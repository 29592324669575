import { useState, useEffect } from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
import { BsPlay, BsStop } from 'react-icons/bs';
import { Ucfirst } from '../../../utils/capitalization';
import { DateNowIsoString } from '../../../utils/date';
import Slider from '../../../components/sliders/Slider';

export const HeatingBoilerTopic = {
    Receive: {
        commandFlags: "commandFlags",
        mainStatus: "mainStatus",
        waterTemperatureSet: "waterTemperatureSet",
        selectedPower: "selectedPower",
        fuelLevel: "fuelLevel",
        waterTemperature: "waterTemperature",
        backWaterTemperature: "backWaterTemperature",
        OutWaterTemp: "OutWaterTemp",
        AmbientTemperature: "AmbientTemperature"
    },
    Transmit: {
        commandFlags: "cmd/HeatingBoiler/commandFlags",
        waterTemperatureSet: "cmd/HeatingBoiler/waterTemperatureSet",
        selectedPower: "cmd/HeatingBoiler/selectedPower"
    }
}

const HeatingBoiler = (props) => {
    const initialDevicesParam = {
        'ziggi': "Heating Boiler",
        'data': [
            {'id': 1, 'name': "Start/Stop", 'key': "startStop", 'val': 1, 'unit': ""},
            {'id': 2, 'name': "Status", 'key': "status", 'val': "n/a", 'unit': ""},
            {'id': 3, 'name': "Boiler Temperature", 'key': "waterTemperatureSet", 'val': 50, 'unit': "°C"},
            {'id': 4, 'name': "Power Mode", 'key': "selectedPower", 'val': 2, 'unit': ""},
            {'id': 5, 'name': "Fuel Level", 'key': "fuelLevel", 'val': 0, 'unit': "%"},
            {'id': 6, 'name': "Water T", 'key': "waterTemperature", 'val': 0, 'unit': "°C"},
            {'id': 7, 'name': "Back Water T", 'key': "backWaterTemperature", 'val': 0, 'unit': "°C"},
            {'id': 8, 'name': "Out Water T", 'key': "outWaterTemperature", 'val': 20, 'unit': "°C"},
            {'id': 9, 'name': "External T", 'key': "externalTemperature", 'val': 0, 'unit': "°C"}
        ]
    }

    const NAME = "Heating Boiler";
    const NO_DEVICE_PARAMETERS = "No device parameters";
    const [devicesParam] = useState(initialDevicesParam)

    const HeatingBolierStatusEnum = {
        0: "Off", 1: "OFF_W_Timer", 2: "TESTFIRE", 3: "HEAT UP", 4: "FUEL IGNI", 5: "IGNI TEST",
        6: "BURNING", 7: "REGULATING FLUID", 8: "REGULATING GAS", 9: "COOL FLUID", 10: "COOL GAS",
        11: "CLEANING", 12: "WAITCOOL", 13: "CHARGEWOOD", 14: "BACKFIRE", 15: "FORCEDLOAD",
        16: "FORCEDOFF", 17: "KEEPFIRE", 18: "AUTOTEST",
        239: "DoorOpen", 240: "FireError", 241: "SecurityPressure", 242: "PelletsMagazineEmpty", 243: "TurbBlocked", 244: "BlockRota", 245: "RTDWaterError",
        246: "RTDGassError", 247: "SecurityError", 248: "PressureError", 249: "NTC1Error", 250: "TC1Error",
        251: "TC2Error", 252: "GassesError", 253: "NoPellets"
    }

    const [commandFlags, setCommandFlags] = useState(devicesParam.data[0].val);
    const [commandFlagsTime, setCommandFlagsTime] = useState("n/a");
    const [mainStatus, setMainStatus] = useState(devicesParam.data[1].val);
    const [mainStatusTime, setMainStatusTime] = useState("n/a");
    const [waterTemperatureSet, setWaterTemperatureSet] = useState(devicesParam.data[2].val);
    const [waterTemperatureSetTime, setWaterTemperatureSetTime] = useState("n/a");
    const [selectedPower, setelectedPower] = useState(devicesParam.data[3].val);
    const [selectedPowerTime, setSelectedPowerTime] = useState("n/a");
    const [fuelLevel, setFuelLevel] = useState(devicesParam.data[4].val);
    const [fuelLevelTime, setFuelLevelTime] = useState("n/a");
    const [waterTemperature, setWaterTemperature] = useState(devicesParam.data[5].val);
    const [waterTemperatureTime, setWaterTemperatureTime] = useState("n/a");
    const [backWaterTemperature, setBackWaterTemperature] = useState(devicesParam.data[6].val);
    const [backWaterTemperatureTime, setBackWaterTemperatureTime] = useState("n/a");
    const [outWaterTemp, setOutWaterTemp] = useState(devicesParam.data[7].val);
    const [outWaterTempTime, setOutWaterTempTime] = useState("n/a");
    const [ambientTemperature, setAmbientTemperature] = useState(devicesParam.data[8].val);
    const [ambientTemperatureTime, setAmbientTemperatureTime] = useState("n/a");

    // TODO: fuelLevel below 15 show notification
    const onMessageReceive = (msg) => {
        var val = parseInt(msg.val);
        // console.log("RECIVE: "+msg.key+"="+msg.val)
        switch(msg.key) {
            case HeatingBoilerTopic.Receive.commandFlags:            setCommandFlags(val);                           setCommandFlagsTime(DateNowIsoString); break;
            case HeatingBoilerTopic.Receive.mainStatus:              setMainStatus(HeatingBolierStatusEnum[val]);    setMainStatusTime(DateNowIsoString); break;
            case HeatingBoilerTopic.Receive.waterTemperatureSet:     setWaterTemperatureSet(val);                    setWaterTemperatureSetTime(DateNowIsoString); break;
            case HeatingBoilerTopic.Receive.selectedPower:           setelectedPower(val);                           setSelectedPowerTime(DateNowIsoString); break;
            case HeatingBoilerTopic.Receive.fuelLevel:               setFuelLevel(val);                              setFuelLevelTime(DateNowIsoString); break;
            case HeatingBoilerTopic.Receive.waterTemperature:        setWaterTemperature(val);                       setWaterTemperatureTime(DateNowIsoString); break;
            case HeatingBoilerTopic.Receive.backWaterTemperature:    setBackWaterTemperature(val);                   setBackWaterTemperatureTime(DateNowIsoString); break;
            case HeatingBoilerTopic.Receive.OutWaterTemp:            setOutWaterTemp(val/10);                        setOutWaterTempTime(DateNowIsoString); break;
            case HeatingBoilerTopic.Receive.AmbientTemperature:      setAmbientTemperature(val/10);                  setAmbientTemperatureTime(DateNowIsoString); break;
            default:
                // console.log("Heating boiler topic not found: " + msg.key);
                break;
        }
    }

    useEffect(() => {
        onMessageReceive(props.message);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const messageSend = (destination, message) => {
        props.onMessageSend(destination, message);
    }

    const onPowerButtonChange = () => {
        let state = (commandFlags === 1 ? 2 : 1)
        messageSend(HeatingBoilerTopic.Transmit.commandFlags, state)
        setCommandFlags(state)
        setCommandFlagsTime(DateNowIsoString)
    }

    const onBoilerTempSliderChange = (value) => {
        let state = parseInt(value)
        messageSend(HeatingBoilerTopic.Transmit.waterTemperatureSet, state)
        setWaterTemperatureSet(state)
        setWaterTemperatureSetTime(DateNowIsoString)
    }

    const onBoilerPowerSliderChange = (value) => {
        let state = parseInt(value)
        messageSend(HeatingBoilerTopic.Transmit.selectedPower, state)
        setelectedPower(state)
        setSelectedPowerTime(DateNowIsoString)
    }

    return devicesParam.data.length > 0 ? (
        <>
        <Row>
            <Col className="mb-2" xs={12} sm={12} md={12} lg={12} key="title">
                <h4 className="text-secondary">{Ucfirst(NAME)}</h4>
            </Col>
        </Row>
        <Row>
            <Col className="mb-2" xs={6} sm={4} md={4} lg={3} key={devicesParam.data[0].id}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[0].name}</b></Card.Header>
                    <Card.Body style={{padding: "0.25rem", fontSize: "2.5rem"}}>
                        <Card.Text style={{padding: "0.7rem"}}>
                            <Button variant={commandFlags === 1 ? "outline-success" : "outline-danger"} block onClick={onPowerButtonChange}>
                                {commandFlags === 1 ? <BsPlay color="green"/> : <BsStop color="red"/>}
                            </Button>
                        </Card.Text>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{commandFlagsTime}</Card.Footer>
                </Card>
            </Col>
            <Col className="mb-2" xs={6} sm={4} md={4} lg={3} key={devicesParam.data[1].id}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[1].name}</b></Card.Header>
                    <Card.Body style={{padding: "0.62rem", fontSize: "2.0rem"}}>
                        <Card.Text style={{color: "bisque"}}>{mainStatus}</Card.Text>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{mainStatusTime}</Card.Footer>
                </Card>
            </Col>
            <Col className="mb-2" xs={12} sm={6} md={4} lg={3} key={devicesParam.data[2].id}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[2].name}</b></Card.Header>
                    <Card.Body style={{padding: "0", fontSize: "2.5rem"}}>
                        <Card.Text style={{color: "mediumaquamarine"}}>{waterTemperatureSet}<small>{devicesParam.data[2].unit}</small></Card.Text>
                        <Slider value={waterTemperatureSet} min={50} max={80} onChange={onBoilerTempSliderChange}/>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{waterTemperatureSetTime}</Card.Footer>
                </Card>
            </Col>
            <Col className="mb-2" xs={12} sm={6} md={4} lg={3} key={devicesParam.data[3].id}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[3].name}</b></Card.Header>
                    <Card.Body style={{padding: "0", fontSize: "2.5rem"}}>
                        <Card.Text style={{color: "mediumaquamarine"}}>{selectedPower}<small>{devicesParam.data[3].unit}</small></Card.Text>
                        <Slider value={selectedPower} min={2} max={5} onChange={onBoilerPowerSliderChange}/>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{selectedPowerTime}</Card.Footer>
                </Card>
            </Col>
            <Col className="mb-2" xs={6} sm={4} md={4} lg={3} key={devicesParam.data[4].id}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[4].name}</b></Card.Header>
                    <Card.Body style={{padding: "0.25rem", fontSize: "2.5rem"}}>
                        <Card.Text style={{color: fuelLevel < 15 ? "firebrick" : "mediumaquamarine"}}>{fuelLevel}<small>{devicesParam.data[4].unit}</small></Card.Text>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{fuelLevelTime}</Card.Footer>
                </Card>
            </Col>
            <Col className="mb-2" xs={6} sm={4} md={4} lg={3} key={devicesParam.data[5].id}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[5].name}</b></Card.Header>
                    <Card.Body style={{padding: "0.25rem", fontSize: "2.5rem"}}>
                        <Card.Text style={{color: waterTemperature > 50 ? "firebrick" : "cornflowerblue"}}>{waterTemperature}<small>{devicesParam.data[5].unit}</small></Card.Text>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{waterTemperatureTime}</Card.Footer>
                </Card>
            </Col>
            <Col className="mb-2" xs={6} sm={4} md={4} lg={3} key={devicesParam.data[6].id}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[6].name}</b></Card.Header>
                    <Card.Body style={{padding: "0.25rem", fontSize: "2.5rem"}}>
                        <Card.Text style={{color: backWaterTemperature > 50 ? "firebrick" : "cornflowerblue"}}>{backWaterTemperature}<small>{devicesParam.data[6].unit}</small></Card.Text>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{backWaterTemperatureTime}</Card.Footer>
                </Card>
            </Col>
            <Col className="mb-2" xs={6} sm={4} md={4} lg={3} key={devicesParam.data[7].id}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[7].name}</b></Card.Header>
                    <Card.Body style={{padding: "0.25rem", fontSize: "2.5rem"}}>
                        <Card.Text style={{color: outWaterTemp > 50 ? "firebrick" : "cornflowerblue"}}>{outWaterTemp}<small>{devicesParam.data[7].unit}</small></Card.Text>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{outWaterTempTime}</Card.Footer>
                </Card>
            </Col>
            <Col className="mb-2" xs={6} sm={4} md={4} lg={3} key={devicesParam.data[8].id}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[8].name}</b></Card.Header>
                    <Card.Body style={{padding: "0.25rem", fontSize: "2.5rem"}}>
                        <Card.Text style={{color: ambientTemperature > 20 ? "firebrick" : "cornflowerblue"}}>{ambientTemperature}<small>{devicesParam.data[8].unit}</small></Card.Text>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{ambientTemperatureTime}</Card.Footer>
                </Card>
            </Col>
        </Row>
        </>
    ) : (
        <Row>
            <Col className="mb-2" xs={12} sm={12} md={12} lg={12}>
                <Card body bg="secondary" className="text-center">{NO_DEVICE_PARAMETERS}</Card>
            </Col>
        </Row>
    )
}

export default HeatingBoiler