import React, { useState, useEffect, useRef } from 'react'
import { Row, Col, ButtonGroup } from 'react-bootstrap'
import { uniqueKeyService } from '../../services/uniqueKeyService';
import { useAuth } from "../../services/authService";
import { alertService } from "../../services/alertService";
import { serialService } from "../../services/serialService";
import { Ucfirst } from '../../utils/capitalization';
import { Crud } from "../../helpers/crudAction";
import { Role } from "../../helpers/userRole";

import CrudTable from '../../components/tables/CrudTable';
import ModalView from '../../components/modals/unique-key/View';
import ModalAdd from '../../components/modals/unique-key/Add';
import ModalEdit from '../../components/modals/unique-key/Edit';
import ModalRemove from '../../components/modals/unique-key/Remove';

const UniqueKey = () => {
    const NAME = "unique key";
    const mountedRef = useRef(true);
    const auth = useAuth();
    const [entities, setEntities] = useState(null);
    const [serials, setSerials] = useState([]);

    useEffect(() => {
        listSerials();
        return () => mountedRef.current = false;
    }, []);

    const listEntities = () => {
        uniqueKeyService.list()
            .then(response => {
                setEntities(response);
            })
            .catch(error => {
                alertService.error(error);
                setEntities([]);
            });
    }

    const listSerials = () => {
        serialService.list()
            .then(response => {
                setSerials(response);
            })
            .catch(error => {
                alertService.error(error);
                setSerials([]);
            })
            .finally(() => {
                listEntities();
            });
    }

    const tableColumns = [
        {
            dataField: 'uuid',
            text: 'UUID'
        },
        {
            dataField: 'manage',
            text: 'Manage',
            headerAlign: 'right',
            align: 'right',
            formatter: manageFormatter,
        }
    ];

    function manageFormatter(cell, row) {
        if (![Role.SUPER, Role.ADMIN].some(role => auth.user.roles.includes(role))) {
            return <ButtonGroup>
                    <ModalView item={row} />
                </ButtonGroup>
        } else {
            return <ButtonGroup>
                    <ModalEdit item={row} serials={serials} refreshList={refreshList} />
                    <ModalRemove item={row} refreshList={refreshList} />
                </ButtonGroup>
        }
    }

    function refreshList(object, action) {
        switch (action) {
            case Crud.ADD:
                setEntities([...entities, object]);
                alertService.success(Ucfirst(NAME) + ' created successfully');
                break;

            case Crud.EDIT:
                setEntities(entities.map((entity) => (entity.id === object.id ? object : entity)));
                alertService.success(Ucfirst(NAME) + ' updated successfully');
                break;

            case Crud.REMOVE:
                setEntities(entities.filter((entity) => entity.id !== object));
                alertService.success(Ucfirst(NAME) + ' removed successfully');
                break;

            default:
                alertService.warn('Something went wrong');
                break;
        }
    }

    return (
        <>
        <Row>
            <Col>
                <h3 className="text-secondary">{Ucfirst(NAME)}s <ModalAdd serials={serials} refreshList={refreshList} /></h3>
            </Col>
        </Row>
        <Row>
            <Col>
                <CrudTable name={NAME} data={entities} columns={tableColumns} />
            </Col>
        </Row>
        </>
    )
}

export default UniqueKey