import React, { useState } from 'react'
import { Form, Button, Modal, InputGroup } from 'react-bootstrap'
import { BsPlus, BsArrowRepeat } from 'react-icons/bs';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';

import { useAuth } from "../../../services/authService";
import { uniqueKeyService } from "../../../services/uniqueKeyService";
import { alertService } from "../../../services/alertService";
import { Crud } from "../../../helpers/crudAction";

const Add = (props) => {
    const TITLE = "Add";
    const SUBMIT = "Submit";
    const SUBMITTING = "Submitting";

    const auth = useAuth();

    const initialValues = {
        uuid: '',
        serials: [],
        note: '',
        activated: true,
        updatedBy: auth.user.username
    };

    const [show, setShow] = useState(false)
    const [submitting, setSubmitting] = useState(false);
    const [entity, setEntity] = useState(initialValues);
    const handleShow = () => { setShow(true); }
    const handleClose = () => {
        setEntity(initialValues);
        setShow(false);
    }

    const validationSchema = Yup.object().shape({
        uuid: Yup.string()
            .required('Required field')
            .min(36, 'Should have at least 36 chars'),
        serials: Yup.array(),
        note: Yup.string(),
        activated: Yup.bool()
    });

    function onSubmit(values) {
        setSubmitting(true);
        values.serials = values.serials.map(serial => parseInt(serial));
        uniqueKeyService.create(values)
            .then((response) => {
                setEntity(response);
                props.refreshList(response, Crud.ADD);
            })
            .catch(error => {
                alertService.error(error);
            })
            .finally(() => {
                setSubmitting(false);
                setEntity(initialValues);
                handleClose();
            });
    }

    function generateUuid(setFieldValue) {
        entity.uuid = uuidv4();
        setFieldValue('uuid', entity.uuid);
        navigator.clipboard.writeText(entity.uuid)
    }

    return (
        <>
        <Button variant="success float-right" size="sm" onClick={handleShow}><BsPlus/></Button>

        <Modal show={show} onHide={handleClose} animation={false} backdrop="static" keyboard={false} id="add-modal">
            <Modal.Header closeButton id="add-modal-header">
                <Modal.Title>{TITLE}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik enableReinitialize={true} initialValues={entity} validationSchema={validationSchema} onSubmit={onSubmit}>
                    {({ handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        errors,
                        setFieldValue
                    }) => (
                        <Form id="add-form" noValidate onSubmit={handleSubmit}>
                            <Form.Group size="lg" controlId="serialNumber">
                                <Form.Label>UUID</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        disabled
                                        type="text"
                                        name="uuid"
                                        value={values.uuid ?? ''}
                                        onChange={handleChange}
                                        isInvalid={touched.uuid && errors.uuid}
                                    />
                                    <InputGroup.Append>
                                        <Button variant="outline-primary" onClick={() => generateUuid(setFieldValue)}><BsArrowRepeat/></Button>
                                    </InputGroup.Append>
                                </InputGroup>
                                <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{errors.uuid}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group size="lg" controlId="serial">
                                <Form.Label>Serials</Form.Label>
                                <Form.Control
                                    as="select"
                                    multiple
                                    name="serials"
                                    value={values.serials}
                                    onChange={handleChange}
                                    isInvalid={touched.serials && errors.serials}
                                >
                                    {props.serials.map((serial, i) => (
                                        <option key={i} value={serial.id} label={serial.number} />
                                    ))}
                                </Form.Control>
                                <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{errors.serials}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group size="lg" controlId="note">
                                <Form.Label>Note</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name="note"
                                    value={values.note ?? ''}
                                    onChange={handleChange}
                                    isInvalid={touched.note && errors.note}
                                />
                                <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{errors.note}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group size="lg" controlId="activated">
                                <Form.Check
                                    name="activated"
                                    label="Activated"
                                    onChange={handleChange}
                                    isInvalid={errors.activated}
                                    feedback={errors.activated}
                                    checked={values.activated}
                                />
                            </Form.Group>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
            <Modal.Footer>
                <Button form="add-form" type="submit" disabled={submitting}>
                    {submitting ? SUBMITTING+"..." : SUBMIT}
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    );
}

export default Add