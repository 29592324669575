import React, { useEffect } from 'react';
import { Route, Switch, useRouteMatch, useHistory } from 'react-router-dom';
import { useAuth } from "../../services/authService";
import PrivateRoute from '../account/PrivateRoute';

import { Notification } from '../../components/Alert';
import Error from '../Error';
import Dashboard from './Dashboard';
import Device from './Device';

function Page() {
    const history = useHistory();
    const { path } = useRouteMatch();
    const auth = useAuth();

    useEffect(() => {
        if (!auth.user) {
            history.push('/');
        }
    }, [auth, history]);

    return (
        <>
            <Notification/>
            <Switch>
                <PrivateRoute exact path={path} component={Dashboard} />
                <PrivateRoute path={path+"dashboard"} component={Dashboard} />
                <PrivateRoute path={path+"device"} component={Device} />
                <Route component={Error} />
            </Switch>
        </>
    );
}

export { Page };