import { useState, useEffect } from 'react';
import { useLocation} from "react-router-dom";
import { Row, Col, Tab, Nav, Dropdown, NavItem, NavLink } from 'react-bootstrap';
import { FaSolarPanel, FaRegPlayCircle, FaStumbleuponCircle } from 'react-icons/fa';
import { BiWater } from 'react-icons/bi';
import { GiBattery75 } from 'react-icons/gi';
import { CgSmartHomeBoiler } from 'react-icons/cg';

import Accumulator from './tab/Accumulator';
import Solar from './tab/Solar';
import HotWater from './tab/HotWater';
import HeatPump from './tab/HeatPump';
import HeatingBoiler from './tab/HeatingBoiler';
import HeatingCircleOne from './tab/HeatingCircleOne';
import HeatingCircleTwo from './tab/HeatingCircleTwo';
import HeatingCircleThree, { HeatingCircleThreeTopic } from './tab/HeatingCircleThree';

import * as MQTT from '../../services/mqttService';

const Topic = {
    Accumulator: "Accumulator",
    SolarHeating: "SolarHeating",
    HotWater: "HotWater",
    HeatingCircle1: "HeatingCircle1",
    HeatingCircle2: "HeatingCircle2",
    HeatingCircle3: "HeatingCircle3",
    HeatPump: "HeatPump",
    HeatingBoiler: "HeatingBoiler"
}

const Device = () => {
    const [device] = useState(useLocation().state)
    const [accumulator, setAccumulator] = useState({key: "", val: ""});
    const [solarHeating, setSolarHeating] = useState({key: "", val: ""});
    const [hotWater, setHotWater] = useState({key: "", val: ""});
    const [heatingCircle1, setHeatingCircle1] = useState({key: "", val: ""});
    const [heatingCircle2, setHeatingCircle2] = useState({key: "", val: ""});
    const [heatingCircle3, setHeatingCircle3] = useState({key: "", val: ""});
    const [heatPump, setHeatPump] = useState({key: "", val: ""});
    const [heatingBoiler, setHeatingBoiler] = useState({key: "", val: ""});

    useEffect(() => {
        mqttStart();
        return () => {
            mqttStop();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const mqttStart = () => {
        MQTT.connect(device.serialNumber, onMessageReceive);
    }

    const mqttStop = () => {
        MQTT.disconnect();
    }

    const mqttSend = (destination, data) => {
        MQTT.sendMesage(destination, data);
    }

    const onMessageReceive = (message) => {
        let topic = message.destinationName.split("/")
        if(topic.length === 4) {
            switchTopic(topic[2], topic[3], message.payloadString);
        }
    }

    const switchTopic = (key, topicKey, topicVal) => {
        // console.log("RECIVE: "+key+"/"+topicKey+"="+topicVal)
        switch(key) {
            case Topic.Accumulator:     setAccumulator({key: topicKey, val: topicVal}); break;
            case Topic.SolarHeating:    setSolarHeating({key: topicKey, val: topicVal}); break;
            case Topic.HotWater:        setHotWater({key: topicKey, val: topicVal}); break;
            case Topic.HeatingCircle1:  setHeatingCircle1({key: topicKey, val: topicVal}); break;
            case Topic.HeatingCircle2:  setHeatingCircle2({key: topicKey, val: topicVal}); break;
            case Topic.HeatingCircle3:
                if (topicKey === HeatingCircleThreeTopic.Receive.HC3DT) {
                    setHeatPump({key: topicKey, val: topicVal});
                }
                setHeatingCircle3({key: topicKey, val: topicVal});
                break;
            case Topic.HeatPump:        setHeatPump({key: topicKey, val: topicVal}); break;
            case Topic.HeatingBoiler:   setHeatingBoiler({key: topicKey, val: topicVal}); break;
            default:
                // console.log("Device topic not found: "+key+" ("+topicKey+": "+topicVal+")");
                break;
        }
    }

    return (
        <Tab.Container id="left-tabs-example" transition={false} defaultActiveKey="accumulator">
            <Row>
                <Col className="mb-2" xs={12} sm={12} md={12} lg={12}>
                    <Nav variant="pills" justify>
                        <Nav.Item><Nav.Link eventKey="accumulator"><GiBattery75 style={{fontSize: "larger"}}/></Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link eventKey="solar"><FaSolarPanel style={{fontSize: "larger"}}/></Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link eventKey="hotWater"><BiWater style={{fontSize: "larger"}}/></Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link eventKey="heatPump"><FaRegPlayCircle style={{fontSize: "larger"}}/></Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link eventKey="heatingBoiler"><CgSmartHomeBoiler style={{fontSize: "larger"}}/></Nav.Link></Nav.Item>
                        <Dropdown as={NavItem}>
                            <Dropdown.Toggle as={NavLink} style={{padding: "0.5rem 0.5rem"}}><FaStumbleuponCircle/></Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item eventKey="heatingCircle1">1</Dropdown.Item>
                                <Dropdown.Item eventKey="heatingCircle2">2</Dropdown.Item>
                                <Dropdown.Item eventKey="heatingCircle3">3</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Nav>
                </Col> 
            </Row>
            <Row>
                <Col className="mb-2" xs={12} sm={12} md={12} lg={12}>
                    <Tab.Content>
                        <Tab.Pane eventKey="accumulator">
                            <Accumulator message={accumulator} onMessageSend={mqttSend}/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="solar">
                            <Solar message={solarHeating} onMessageSend={mqttSend}/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="hotWater">
                            <HotWater message={hotWater} onMessageSend={mqttSend}/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="heatPump">
                            <HeatPump message={heatPump} onMessageSend={mqttSend}/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="heatingBoiler">
                            <HeatingBoiler message={heatingBoiler} onMessageSend={mqttSend}/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="heatingCircle1">
                            <HeatingCircleOne message={heatingCircle1} onMessageSend={mqttSend}/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="heatingCircle2">
                            <HeatingCircleTwo message={heatingCircle2} onMessageSend={mqttSend}/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="heatingCircle3">
                            <HeatingCircleThree message={heatingCircle3} onMessageSend={mqttSend}/>
                        </Tab.Pane>
                    </Tab.Content>
                </Col> 
            </Row>
        </Tab.Container>
    )
}

export default Device