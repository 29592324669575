import React, { useState } from 'react'
import { Form, Button, Modal } from 'react-bootstrap'
import { BsPlus } from 'react-icons/bs';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { useAuth } from "../../../services/authService";
import { userService } from "../../../services/userService";
import { alertService } from "../../../services/alertService";
import { Role } from "../../../helpers/userRole";
import { Crud } from "../../../helpers/crudAction";

const Add = (props) => {
    const TITLE = "Add";
    const SUBMIT = "Submit";
    const SUBMITTING = "Submitting";

    const auth = useAuth();

    const initialValues = {
        uniqueKey: '',
        email: '',
        firstName: '',
        lastName: '',
        address: '',
        mobile: '',
        roles:[Role.USER],
        newsletter: false,
        grafana: false,
        activated: true,
        inviteByEmail: false,
        updatedBy: auth.user.username
    };

    const [show, setShow] = useState(false)
    const [submitting, setSubmitting] = useState(false);
    const [entity, setEntity] = useState(initialValues);
    const handleShow = () => { setShow(true); }
    const handleClose = () => { setShow(false); }

    const validationSchema = Yup.object().shape({
        uniqueKey: Yup.string(),
        email: Yup.string().required('Required field').email('Invalid Email address'),
        firstName: Yup.string().transform(value => (value === null ? '' : value)),
        lastName: Yup.string().transform(value => (value === null ? '' : value)),
        address: Yup.string().transform(value => (value === null ? '' : value)),
        mobile: Yup.string().transform(value => (value === null ? '' : value)),
        roles: Yup.array().of(Yup.string().required('Required field')),
        newsletter: Yup.bool(),
        grafana: Yup.bool(),
        inviteByEmail: Yup.bool(),
        activated: Yup.bool()
    });

    function onSubmit(values) {
        setSubmitting(true);
        values.uniqueKey = values.uniqueKey > 0 ? parseInt(values.uniqueKey) : null;
        userService.create(values)
            .then((response) => {
                setEntity(response);
                props.refreshList(response, Crud.ADD);
            })
            .catch(error => {
                alertService.error(error);
            })
            .finally(() => {
                setSubmitting(false);
                setEntity(initialValues);
                handleClose();
            });
    }

    return (
        <>
        <Button variant="success float-right" size="sm" onClick={handleShow}><BsPlus/></Button>

        <Modal show={show} onHide={handleClose} animation={false} backdrop="static" keyboard={false} id="add-modal">
            <Modal.Header closeButton id="add-modal-header">
                <Modal.Title>{TITLE}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik enableReinitialize={true} initialValues={entity} validationSchema={validationSchema} onSubmit={onSubmit}>
                    {({ handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        errors,
                    }) => (
                        <Form id="add-form" noValidate onSubmit={handleSubmit}>
                            <Form.Group size="lg" controlId="uniqueKey">
                                <Form.Label>UUID</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="uniqueKey"
                                    value={values.uniqueKey}
                                    onChange={handleChange}
                                    isInvalid={touched.uniqueKey && errors.uniqueKey}
                                >
                                    <option key={0} value={0} label='...' />
                                    {props.uniqueKeys.map((uniqueKey, i) => (
                                        <option key={i} value={uniqueKey.id} label={uniqueKey.uuid} />
                                    ))}
                                </Form.Control>
                                <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{errors.uniqueKey}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group size="lg" controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="email"
                                    value={values.email ?? ''}
                                    onChange={handleChange}
                                    isInvalid={touched.email && errors.email}
                                />
                                <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group size="lg" controlId="firstName">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="firstName"
                                    value={values.firstName ?? ''}
                                    onChange={handleChange}
                                    isInvalid={touched.firstName && errors.firstName}
                                />
                                <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group size="lg" controlId="lastName">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="lastName"
                                    value={values.lastName ?? ''}
                                    onChange={handleChange}
                                    isInvalid={touched.lastName && errors.lastName}
                                />
                                <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group size="lg" controlId="address">
                                <Form.Label>Address</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="address"
                                    value={values.address ?? ''}
                                    onChange={handleChange}
                                    isInvalid={touched.address && errors.address}
                                />
                                <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{errors.address}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group size="lg" controlId="mobile">
                                <Form.Label>Mobile</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="mobile"
                                    value={values.mobile ?? ''}
                                    onChange={handleChange}
                                    isInvalid={touched.mobile && errors.mobile}
                                />
                                <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{errors.mobile}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group size="lg" controlId="roles">
                                <Form.Label>Role</Form.Label>
                                <Form.Control
                                    as="select"
                                    multiple
                                    name="roles"
                                    value={values.roles}
                                    onChange={handleChange}
                                    isInvalid={touched.roles && errors.roles}
                                >
                                    <option value={[Role.ADMIN]} label="Admin" />
                                    <option value={[Role.OPERATOR]} label="Operator" />
                                    <option value={[Role.USER]} label="User" />
                                </Form.Control>
                                <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{errors.roles}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group size="lg" controlId="newsletter">
                                <Form.Check
                                    name="newsletter"
                                    label="Newsletter"
                                    onChange={handleChange}
                                    isInvalid={errors.newsletter}
                                    feedback={errors.newsletter}
                                    checked={values.newsletter}
                                />
                            </Form.Group>
                            <Form.Group size="lg" controlId="grafana">
                                <Form.Check
                                    name="grafana"
                                    label="Grafana"
                                    onChange={handleChange}
                                    isInvalid={errors.grafana}
                                    feedback={errors.grafana}
                                    checked={values.grafana}
                                />
                            </Form.Group>
                            <Form.Group size="lg" controlId="inviteByEmail">
                                <Form.Check
                                    name="inviteByEmail"
                                    label="Invite via Email"
                                    onChange={handleChange}
                                    isInvalid={errors.inviteByEmail}
                                    feedback={errors.inviteByEmail}
                                    checked={values.inviteByEmail}
                                />
                            </Form.Group>
                            <Form.Group size="lg" controlId="activated">
                                <Form.Check
                                    name="activated"
                                    label="Activated"
                                    onChange={handleChange}
                                    isInvalid={errors.activated}
                                    feedback={errors.activated}
                                    checked={values.activated}
                                />
                            </Form.Group>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
            <Modal.Footer>
                <Button form="add-form" type="submit" disabled={submitting}>
                    {submitting ? SUBMITTING+"..." : SUBMIT}
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    );
}

export default Add