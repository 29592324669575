import { useAuth } from "../services/authService";

function Footer() {
    const auth = useAuth();
    if (!auth.user) return null;

    return (
        <footer className="fixed-bottom bg-dark text-white px-3">
            <small className="float-left">&copy; {process.env.REACT_APP_COMPANY_NAME}</small>
            <small className="float-right">v{process.env.REACT_APP_VERSION}</small>
        </footer>
    );
}

export default Footer;