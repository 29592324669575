import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { BsGear, BsPersonSquare, BsFillPersonFill, BsBackspace } from 'react-icons/bs';
import { FaSignOutAlt } from 'react-icons/fa';

import { useAuth } from "../services/authService";
import { Role } from "../helpers/userRole";
import ModalUserEdit from "../components/modals/profile/Edit";

const Header = () => {
    const history = useHistory();
    const auth = useAuth();
    const [show, setShow] = useState(false)

    if (!auth.user) return null;

    const handleShow = () => { setShow(true); }
    const handleClose = () => { setShow(false); }

    return (
        <>
        <Navbar bg="dark" variant="dark" sticky="top" expand="lg" collapseOnSelect>
            <Navbar.Brand as={Link} to="/dashboard">
                <img alt="" src="/logo32.png" height="30" className="d-inline-block align-top"/>
            </Navbar.Brand>
            <Nav className="mr-auto"></Nav>
            <Nav.Link onClick={() => history.goBack()}><BsBackspace style={{fontSize: "larger"}}/></Nav.Link>
            <NavDropdown title={<BsFillPersonFill/>} id="basic-nav-dropdown" alignRight>
                <NavDropdown.Item onClick={handleShow}><BsPersonSquare style={{fontSize: "larger"}}/> Profile</NavDropdown.Item>
                {[Role.SUPER, Role.ADMIN, Role.OPERATOR].some(role => auth.user.roles.includes(role)) &&
                    <NavDropdown.Item as={Link} to="/admin/dashboard"><BsGear/> Settings</NavDropdown.Item>
                }
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to="/" onClick={auth.logout}><FaSignOutAlt/> Logout</NavDropdown.Item>
            </NavDropdown>
        </Navbar>

        <ModalUserEdit show={show} handleClose={handleClose}/>
        </>
    );
}

export default Header;