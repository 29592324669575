import { useState, useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { Ucfirst } from '../../../utils/capitalization';
import { DateNowIsoString } from '../../../utils/date';
import Slider from '../../../components/sliders/Slider';
import { TemperatureToOneDecimal } from '../../../utils/conversion';
import Led from '../../../components/leds/Led';
import Select from '../../../components/select/Select';

export const HeatingCircleTwoTopic = {
    Receive: {
        Par_HCV2_Mode: "Par_HCV2_Mode",
        HC2STS: "HC2STS",
        Par_HCV2WeatherCurve: "Par_HCV2WeatherCurve",
        HC2DT: "HC2DT",
        HC2T: "HC2T",
        HC2R: "HC2R",
        HC2P: "HC2P"
    },
    Transmit: {
        Par_HCV2_Mode: "cmd/HeatingCircle2/Par_HCV2_Mode",
        Par_HCV2WeatherCurve: "cmd/HeatingCircle2/Par_HCV2WeatherCurve",
        HC2DT: "cmd/HeatingCircle2/HC2DT"
    }
}

const HeatingCircleTwo = (props) => {
    const initialDevicesParam = {
        'ziggi': "Heating Circle 2",
        'data': [
            {'id': 1, 'name': "Operating Mode", 'key': "startStop", 'val': 0, 'unit': ""},
            {'id': 2, 'name': "Status", 'key': "status", 'val': "n/a", 'unit': ""},
            {'id': 3, 'name': "Heating Curve", 'key': "heatingCurve", 'val': 0, 'unit': ""},
            {'id': 4, 'name': "Desired Temperature", 'key': "desiredTemperature", 'val': 0, 'unit': "°C"},
            {'id': 5, 'name': "Water T", 'key': "waterTemperature", 'val': 0, 'unit': "°C"},
            {'id': 6, 'name': "Room Thermostat", 'key': "roomThermostat", 'val': false, 'unit': "°C"},
            {'id': 7, 'name': "Water Pump", 'key': "waterPump", 'val': false, 'unit': ""}
        ]
    }

    const CircleStatusEnum = { 0: "Off", 1: "Weat.", 2: "Ther.", 3: "Weat.+", 240: "HCx" }

    const operationModeOptions = [
        { value: 0, label: "Off" },
        { value: 1, label: "Weather" },
        { value: 2, label: "Thermostat" },
        { value: 3, label: "Advanced" }
    ];

    const NAME = "Heating Circle 2";
    const NO_DEVICE_PARAMETERS = "No device parameters";
    const [devicesParam] = useState(initialDevicesParam)

    const [parHCV2Mode, setParHCV2Mode] = useState(devicesParam.data[0].val);
    const [parHCV2ModeTime, setParHCV2ModeTime] = useState("n/a");
    const [HC2STS, setHC2STS] = useState(devicesParam.data[1].val);
    const [HC2STSTime, setHC2STSTime] = useState("n/a");
    const [HCV2WeatherCurve, setHCV2WeatherCurve] = useState(devicesParam.data[2].val);
    const [HCV2WeatherCurveTime, setHCV2WeatherCurveTime] = useState("n/a");
    const [HC2DT, setHC2DT] = useState(devicesParam.data[3].val);
    const [HC2DTTime, setHC2DTTime] = useState("n/a");
    const [HC2T, setHC2T] = useState(TemperatureToOneDecimal(devicesParam.data[4].val));
    const [HC2TTime, setHC2TTime] = useState("n/a");
    const [HC2R, setHC2R] = useState(devicesParam.data[5].val);
    const [HC2RTime, setHC2RTime] = useState("n/a");
    const [HC2P, setHC2P] = useState(devicesParam.data[6].val);
    const [HC2PTime, setHC2PTime] = useState("n/a");

    const onMessageReceive = (msg) => {
        var val = parseInt(msg.val);
        // console.log("RECIVE: "+msg.key+"="+msg.val)
        switch(msg.key) {
            case HeatingCircleTwoTopic.Receive.Par_HCV2_Mode:           setParHCV2Mode(val);                    setParHCV2ModeTime(DateNowIsoString); break;
            case HeatingCircleTwoTopic.Receive.HC2STS:                  setHC2STS(CircleStatusEnum[val]);       setHC2STSTime(DateNowIsoString); break;
            case HeatingCircleTwoTopic.Receive.Par_HCV2WeatherCurve:    setHCV2WeatherCurve(val);               setHCV2WeatherCurveTime(DateNowIsoString); break;
            case HeatingCircleTwoTopic.Receive.HC2DT:                   setHC2DT(val);                          setHC2DTTime(DateNowIsoString); break;
            case HeatingCircleTwoTopic.Receive.HC2T:                    setHC2T(TemperatureToOneDecimal(val));  setHC2TTime(DateNowIsoString); break;
            case HeatingCircleTwoTopic.Receive.HC2R:                    setHC2R(val === 0 ? true : false);      setHC2RTime(DateNowIsoString); break;
            case HeatingCircleTwoTopic.Receive.HC2P:                    setHC2P(val === 1 ? true : false);      setHC2PTime(DateNowIsoString); break;
            default:
                // console.log("Heating circle 2 topic not found: " + msg.key);
                break;
        }
    }

    useEffect(() => {
        onMessageReceive(props.message);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const messageSend = (destination, message) => {
        props.onMessageSend(destination, message);
    }

    const onOperatingModeChange = (value) => {
        messageSend(HeatingCircleTwoTopic.Transmit.Par_HCV2_Mode, value)
        // setParHCV2Mode(value)
        // setParHCV2ModeTime(DateNowIsoString)
    }

    const onHeatingSliderChange = (value) => {
        let state = parseInt(value)
        messageSend(HeatingCircleTwoTopic.Transmit.Par_HCV2WeatherCurve, state)
        setHCV2WeatherCurve(state)
        setHCV2WeatherCurveTime(DateNowIsoString)
    }

    const onTemperatureSliderChange = (value) => {
        let state = parseInt(value)
        messageSend(HeatingCircleTwoTopic.Transmit.HC2DT, state)
        setHC2DT(state)
        setHC2DTTime(DateNowIsoString)
    }

    return devicesParam.data.length > 0 ? (
        <>
        <Row>
            <Col className="mb-2" xs={12} sm={12} md={12} lg={12} key="title">
                <h4 className="text-secondary">{Ucfirst(NAME)}</h4>
            </Col>
        </Row>
        <Row>
            <Col className="mb-2" xs={6} sm={4} md={4} lg={3} key={devicesParam.data[0].id}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[0].name}</b></Card.Header>
                    <Card.Body style={{padding: "0.25rem", fontSize: "2.5rem"}}>
                        <Card.Text style={{padding: "0.7rem"}}>
                            <Select value={parHCV2Mode} onChange={onOperatingModeChange} options={operationModeOptions} />
                        </Card.Text>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{parHCV2ModeTime}</Card.Footer>
                </Card>
            </Col>
            <Col className="mb-2" xs={6} sm={4} md={4} lg={3} key={devicesParam.data[1].id}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[1].name}</b></Card.Header>
                    <Card.Body style={{padding: "0.62rem", fontSize: "2.0rem"}}>
                        <Card.Text style={{color: "bisque"}}>{HC2STS}</Card.Text>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{HC2STSTime}</Card.Footer>
                </Card>
            </Col>
            <Col className="mb-2" xs={12} sm={6} md={4} lg={3} key={devicesParam.data[2].id}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[2].name}</b></Card.Header>
                    <Card.Body style={{padding: "0", fontSize: "2.5rem"}}>
                        <Card.Text style={{color: "mediumaquamarine"}}>{HCV2WeatherCurve}<small>{devicesParam.data[2].unit}</small></Card.Text>
                        <Slider value={HCV2WeatherCurve} min={0} max={29} onChange={onHeatingSliderChange}/>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{HCV2WeatherCurveTime}</Card.Footer>
                </Card>
            </Col>
            <Col className="mb-2" xs={12} sm={6} md={4} lg={3} key={devicesParam.data[3].id}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[3].name}</b></Card.Header>
                    <Card.Body style={{padding: "0", fontSize: "2.5rem"}}>
                        <Card.Text style={{color: "mediumaquamarine"}}>{HC2DT}<small>{devicesParam.data[3].unit}</small></Card.Text>
                        <Slider value={HC2DT} min={0} max={100} onChange={onTemperatureSliderChange}/>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{HC2DTTime}</Card.Footer>
                </Card>
            </Col>
            <Col className="mb-2" xs={6} sm={4} md={4} lg={3} key={devicesParam.data[4].id}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[4].name}</b></Card.Header>
                    <Card.Body style={{padding: "0.25rem", fontSize: "2.5rem"}}>
                        <Card.Text style={{color: HC2T > 50 ? "firebrick" : "cornflowerblue"}}>{HC2T}<small>{devicesParam.data[4].unit}</small></Card.Text>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{HC2TTime}</Card.Footer>
                </Card>
            </Col>
            <Col className="mb-2" xs={6} sm={4} md={4} lg={3} key={devicesParam.data[5].id}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[5].name}</b></Card.Header>
                    <Card.Body style={{padding: "1.125rem", fontSize: "2.5rem"}}>
                        <Led value={HC2R}/>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{HC2RTime}</Card.Footer>
                </Card>
            </Col>
            <Col className="mb-2" xs={6} sm={4} md={4} lg={3} key={devicesParam.data[6].id}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[6].name}</b></Card.Header>
                    <Card.Body style={{padding: "1.125rem", fontSize: "2.5rem"}}>
                        <Led value={HC2P}/>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{HC2PTime}</Card.Footer>
                </Card>
            </Col>
        </Row>
        </>
    ) : (
        <Row>
            <Col className="mb-2" xs={12} sm={12} md={12} lg={12}>
                <Card body bg="secondary" className="text-center">{NO_DEVICE_PARAMETERS}</Card>
            </Col>
        </Row>
    )
}

export default HeatingCircleTwo