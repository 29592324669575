import React, { useEffect, useState } from "react";

const Led = (props) => {
    const [ledActive, setLedActive] = useState("off");

    useEffect(() => {
        setLedActive(props.value ? "" : "off");
    }, [props.value]);

    return (
        <div className={`led-green ${ledActive}`}></div>
    )
}

export default Led