import { v4 as uuidv4 } from 'uuid';
import { Client, Message } from 'paho-mqtt';

let CLIENT = null;
let TOPIC = null;

export const connect = (serialNumber, onMessageReceive) => {
    CLIENT = new Client(process.env.REACT_APP_MQTT_DOMAIN, Number(process.env.REACT_APP_MQTT_PORT), "", uuidv4())
    TOPIC = process.env.REACT_APP_MQTT_TOPIC_PREFIX + '/' + serialNumber
    CLIENT.onConnectionLost = _onConnectionLost;
    CLIENT.onMessageArrived = onMessageReceive;
    CLIENT.connect({
        userName: process.env.REACT_APP_MQTT_USERNAME,
        password: process.env.REACT_APP_MQTT_PASSWORD,
        useSSL: true,
        onSuccess: _onConnectSuccess,
        onFailure: _onConnectFailure
    });

    return CLIENT;
}

export const sendMesage = (destination, data) => {
    let message = new Message(data.toString());
    message.destinationName = TOPIC + "/" + destination;
    CLIENT.send(message);
}

export const disconnect = () => {
    if (CLIENT.isConnected) {
        // CLIENT.disconnect();
    }
    CLIENT = null;
    TOPIC = null;
}

const _onConnectSuccess = () => {
    let options = {
        // qos: 0,
        // invocationContext: {foo: true},  // Passed to success / failure callback
        onSuccess: _onSubscribeSuccess,
        onFailure: _onSubscribeFailure,
        timeout: 30
    };
    CLIENT.subscribe(TOPIC + "/#", options);
}

const _onConnectFailure = (e) => {
    console.log("onConnectFailure: ", e);
    return null;
}

const _onConnectionLost = (responseObject) => {
    if (responseObject.errorCode !== 0) {
        console.log("onConnectionLost:" + responseObject.errorMessage);
    }
}

const _onSubscribeSuccess = (e) => {
    // console.log("onSubscribeSuccess: ", e);
}

const _onSubscribeFailure = (e) => {
    console.log("onSubscribeFailure: ", e);
}