import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';

const Select = (props) => {
    const [value, setValue] = useState(props.value);
    const [options] = useState(props.options);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const handleChangeValue = (event) => {
        const value = event.target.value;
        setValue(value);
        props.onChange(value);
    }

    return (
        <Form.Control
            as="select"
            value={value}
            onChange={handleChangeValue}
            style={{background: "#161719", color: "#FFFFFF"}}
        >
            {options && options.map((option, index) => {
                return (<option key={index} value={option.value} label={option.label} />);
            })}
        </Form.Control>
    )
}

export default Select