import { axiosWrapper } from '../helpers/axiosWrapper';

const END_POINT = process.env.REACT_APP_BE_API_URL+"/devices"

const list = () =>                          { return axiosWrapper.get(END_POINT, {}) };
const get = (id) =>                         { return axiosWrapper.get(END_POINT+"/"+id, {}) };
const create = (data) =>                    { return axiosWrapper.post(END_POINT, data) };
const update = (id, data) =>                { return axiosWrapper.put(END_POINT+"/"+id, data) };
const remove = (id) =>                      { return axiosWrapper.delete(END_POINT+"/"+id) };

const getByUserId = (userId) =>             { return axiosWrapper.get(END_POINT+"/by_user_id", {userId: userId}) };
const getByUserEmail = (userEmail) =>       { return axiosWrapper.get(END_POINT+"/by_user_email", {userEmail: userEmail}) };
const listByOperatorId = (operatorId) =>    { return axiosWrapper.get(END_POINT+"/by_operator_id", {operatorId: operatorId}) };

export const deviceService = {
    list,
    get,
    create,
    update,
    remove,
    getByUserId,
    getByUserEmail,
    listByOperatorId
};