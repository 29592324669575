import React, { useState, useEffect, useRef } from 'react'
import { Form, Button, Modal } from 'react-bootstrap'
import { Formik } from 'formik';
import * as Yup from 'yup';

import { useAuth } from "../../../services/authService";
import { userService } from "../../../services/userService";
import { alertService } from "../../../services/alertService";

const Profile = (props) => {
    const TITLE     = "Edit";
    const SUBMIT    = "Submit";
    const SUBMITTING = "Submitting";

    const auth = useAuth();
    const mountedRef = useRef(true);

    const [submitting, setSubmitting] = useState(false);

    const initialValues = {
        email:'',
        firstName: '',
        lastName: '',
        address: '',
        mobile: '',
        newsletter: false
    };

    const [profile, setProfile] = useState(initialValues);

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().transform(value => (value === null ? '' : value)),
        lastName: Yup.string().transform(value => (value === null ? '' : value)),
        address: Yup.string().transform(value => (value === null ? '' : value)),
        mobile: Yup.string().transform(value => (value === null ? '' : value)),
        newsletter: Yup.bool()
    });

    useEffect(() => {
        getUser(auth.user.username);
        return () => mountedRef.current = false;
    }, [auth]);

    const getUser = (email) => {
        userService.getByEmail(email)
            .then(response => {
                setProfile(response);
            })
            .catch(error => {
                alertService.error(error);
            });
    }

    function onSubmit(values) {
        setSubmitting(true);
        userService.update(profile.id, values)
            .then((response) => {
                setProfile(response);
                alertService.success('Profile updated successfully');
            })
            .catch(error => {
                alertService.error(error);
            })
            .finally(() => {
                setSubmitting(false);
                props.handleClose();
            });
    }

    return (
        <Modal show={props.show} onHide={props.handleClose} animation={false} backdrop="static" keyboard={false} id="profile-modal">
            <Modal.Header closeButton id="profile-modal-header">
                <Modal.Title>{TITLE}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik enableReinitialize={true} initialValues={profile} validationSchema={validationSchema} onSubmit={onSubmit}>
                    {({ handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        errors,
                    }) => (
                        <Form id="profile-form" noValidate onSubmit={handleSubmit}>
                            <Form.Group size="lg" controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="email"
                                    value={values.email ?? ''}
                                    onChange={handleChange}
                                    isInvalid={touched.email && errors.email}
                                    disabled={true}
                                />
                                <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group size="lg" controlId="firstName">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="firstName"
                                    value={values.firstName ?? ''}
                                    onChange={handleChange}
                                    isInvalid={touched.firstName && errors.firstName}
                                />
                                <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group size="lg" controlId="lkastName">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="lastName"
                                    value={values.lastName ?? ''}
                                    onChange={handleChange}
                                    isInvalid={touched.lastName && errors.lastName}
                                />
                                <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group size="lg" controlId="address">
                                <Form.Label>Address</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="address"
                                    value={values.address ?? ''}
                                    onChange={handleChange}
                                    isInvalid={touched.address && errors.address}
                                />
                                <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{errors.address}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group size="lg" controlId="mobile">
                                <Form.Label>Mobile</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="mobile"
                                    value={values.mobile ?? ''}
                                    onChange={handleChange}
                                    isInvalid={touched.mobile && errors.mobile}
                                />
                                <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{errors.mobile}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Check
                                    name="newsletter"
                                    label="Newsletter"
                                    onChange={handleChange}
                                    isInvalid={errors.newsletter}
                                    feedback={errors.newsletter}
                                    checked={values.newsletter}
                                />
                            </Form.Group>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
            <Modal.Footer>
                <Button form="profile-form" type="submit" disabled={submitting}>
                    {submitting ? SUBMITTING+"..." : SUBMIT}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default Profile