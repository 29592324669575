import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom';
import { Row, Col, Card, ButtonGroup, Button } from 'react-bootstrap'
import { deviceService } from '../../services/deviceService';
import { Ucfirst } from '../../utils/capitalization';
import ModalDeviceAdd from '../../components/modals/device/Add';
import ModalDeviceRemove from '../../components/modals/device/Remove';

import { alertService } from "../../services/alertService";
import { useAuth } from "../../services/authService";

const Dashboard = () => {
    const auth = useAuth();
    const mountedRef = useRef(true);
    const [devices, setDevices] = useState([])
    const MAX_VIEW_LENGTH = 12;

    useEffect(() => {
        listDevices(auth.user.id);
        return () => mountedRef.current = false;
    }, [auth.user.id]);

    const listDevices = (userId) => {
        alertService.clear();
        deviceService.getByUserId(userId)
            .then(response => {
                setDevices(response);
            })
            .catch(error => {
                alertService.error(error);
            });
    }

    const addDevice = (device) => {
        deviceService.create(device)
            .then(response => {
                setDevices([...devices, response]);
                alertService.success('Device created successfully');
            })
            .catch(error => {
                alertService.error(error);
            });
    }

    const removeDevice = (id) => {
        deviceService.remove(id)
            .then(() => {
                setDevices(devices.filter((device) => device.id !== id));
                alertService.success('Device removed successfully');
            })
            .catch(error => {
                alertService.error(error);
            });
    }

    return (
        <Row>
            <Col className="mb-2" xs={12} sm={6} md={4} lg={3}>
                <ModalDeviceAdd addDevice={addDevice}/>
            </Col>
            {devices && devices.length > 0 ? (
                devices.map((device) => (
                    <Col className="mb-2" xs={12} sm={6} md={4} lg={3} key={device.id}>
                        <ButtonGroup style={{display: "flex"}}>
                            <Button style={{textAlign: "left"}} variant="dark" size="lg" block as={Link} to={{pathname: '/device', state: device}}>
                                {Ucfirst(device.title.length > MAX_VIEW_LENGTH ?
                                    device.title.substring(0, MAX_VIEW_LENGTH)+'...' :
                                    device.title)}
                                {' '}
                                <small className="text-muted">(
                                    {device.serialNumber.length > MAX_VIEW_LENGTH ?
                                        device.serialNumber.substring(0, MAX_VIEW_LENGTH)+'...' :
                                        device.serialNumber})
                                </small>
                            </Button>
                            <ModalDeviceRemove item={device} removeDevice={removeDevice}/>
                        </ButtonGroup>
                    </Col>
                ))
            ) : (
                <Col className="mb-2" xs={12} sm={12} md={12} lg={12}>
                    <Card body bg="secondary" className="text-center">No device yet</Card>
                </Col>
            )}
        </Row>
    )
}

export default Dashboard