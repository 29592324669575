import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { BsXCircle } from 'react-icons/bs'

import { deviceService } from "../../../services/deviceService";
import { alertService } from "../../../services/alertService";
import { Crud } from "../../../helpers/crudAction";

const Remove = (props) => {
    const TITLE = "Remove";
    const SUBMIT   = "Submit";
    const SUBMITTING = "Submitting";
    const TEXT  = "Are you sure you want to remove entity";

    const [show, setShow] = useState(false)
    const [submitting, setSubmitting] = useState(false);
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    function onSubmit() {
        setSubmitting(true);
        deviceService.remove(props.item.id)
            .then(() => {
                props.refreshList(props.item.id, Crud.REMOVE);
            })
            .catch(error => {
                alertService.error(error);
            })
            .finally(() => {
                setSubmitting(false);
                handleClose();
            });
    }

    return (
        <>
        <Button variant="danger float-right" size="sm" onClick={handleShow}><BsXCircle/></Button>

        <Modal show={show} onHide={handleClose} animation={false} backdrop="static" keyboard={false} id="remove-modal">
            <Modal.Header closeButton id="remove-modal-header">
                <Modal.Title>{TITLE}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {TEXT} - "<b>{props.item.title}</b>"?
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onSubmit} disabled={submitting}>
                    {submitting ? SUBMITTING+"..." : SUBMIT}
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    );
}

export default Remove