import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from "../../services/authService";

function PrivateRoute({ component: Component, roles, ...rest }) {
    const auth = useAuth();

    return (
        <Route {...rest} render={props => {
            if (!auth.user) {
                return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />
            }

            // Is route restricted by role
            // if (roles && roles.indexOf(user.role) === -1) {
            //     // role not authorized so redirect to home page
            //     return <Redirect to={{ pathname: '/'}} />
            // }

            return <Component {...props} />
        }} />
    );
}

export default PrivateRoute;