import React, { useEffect, useState } from 'react';
import { Route, Switch, useRouteMatch, useHistory } from 'react-router-dom';
import { Image } from "react-bootstrap";
import { useAuth } from "../../services/authService";

import { Notification } from '../../components/Alert';
import Error from '../Error';
import Login from './Login';
import Register from './Register';
import VerifyEmail from './VerifyEmail';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';

function Account() {
    const history = useHistory();
    const { path } = useRouteMatch();
    const auth = useAuth();

    const [title, setTitle] = useState('');

    useEffect(() => {
        if (auth.user) {
            history.push('/');
        }
    }, [auth.user, history]);

    const componentTitle = (title) => {
        setTitle(title);
    }

    return (
        <div style={{margin: "2rem auto 0rem auto", width: "278px", color: "#adadad",}}>
            <Image src="/logo.png" style={{marginBottom: "40px"}} />
            <Notification/>
            <h5 style={{textAlign: "center"}}>{title}</h5>
            <Switch>
                <Route path={path+"/login"} render={(props) => (<Login {...props} componentTitle={componentTitle} />)} />
                <Route path={path+"/register"} render={(props) => (<Register {...props} componentTitle={componentTitle} />)} />
                <Route path={path+"/verify-email"} render={(props) => (<VerifyEmail {...props} componentTitle={componentTitle} />)} />
                <Route path={path+"/forgot-password"} render={(props) => (<ForgotPassword {...props} componentTitle={componentTitle} />)} />
                <Route path={path+"/reset-password"} render={(props) => (<ResetPassword {...props} componentTitle={componentTitle} />)} />
                <Route component={Error} />
            </Switch>
            <div className="text-center" style={{margin: "2rem auto 0rem"}}>
                <div>
                    <small>&copy; 2020 {process.env.REACT_APP_COMPANY_NAME}, All Rights Reserved</small>
                </div>
                <div>
                    <small>Terms | v{process.env.REACT_APP_VERSION}</small>
                </div>
            </div>
        </div>
    );
}

export { Account };