import React, { useState } from 'react'
import { Modal, Card, ListGroup, ListGroupItem, Button } from 'react-bootstrap'
import { BsX, BsCheck, BsCardText } from 'react-icons/bs';
import { Ucfirst } from '../../../utils/capitalization';

const View = (props) => {
    const TITLE = "User";

    const [show, setShow] = useState(false)
    const [user] = useState(props.item);
    const handleShow = () => { setShow(true); }
    const handleClose = () => { setShow(false); }

    return (
        <>
        <Button variant="primary float-right" size="sm" onClick={handleShow}><BsCardText/></Button>

        <Modal show={show} onHide={handleClose} animation={false} backdrop="static" keyboard={false} id="view-modal">
            <Modal.Header closeButton id="view-modal-header">
                <Modal.Title>{Ucfirst(TITLE)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Card>
                    <Card.Img variant="top" src="" />
                    <Card.Body>
                        <Card.Title>{Ucfirst(user.firstName)} {Ucfirst(user.lastName)}</Card.Title>
                        <Card.Text>{user.email}</Card.Text>
                    </Card.Body>
                    <ListGroup>
                        <ListGroupItem><b>ID:</b> {user.id}</ListGroupItem>
                        <ListGroupItem>
                            <b>Activated:</b> {user.activated ? (<BsCheck className="text-success"/>) : (<BsX className="text-danger"/>)}
                        </ListGroupItem>
                        <ListGroupItem><b>Address:</b> {user.address ?? "/"}</ListGroupItem>
                        <ListGroupItem><b>Mobile:</b> {user.mobile ?? "/"}</ListGroupItem>
                        <ListGroupItem>
                            <b>Newsletter:</b> {user.newsletter ? (<BsCheck className="text-success"/>) : (<BsX className="text-danger"/>)}
                        </ListGroupItem>
                        <ListGroupItem>
                            <b>Grafana:</b> {user.grafana ? (<BsCheck className="text-success"/>) : (<BsX className="text-danger"/>)}
                        </ListGroupItem>
                        <ListGroupItem><b>Created:</b> {user.createdAt ? new Date(user.createdAt).toUTCString() : "/"}</ListGroupItem>
                        <ListGroupItem><b>Roles:</b> {user.roles.join()}</ListGroupItem>
                        <ListGroupItem>
                            <b>Devices: </b>
                            {user.devices.length > 0 ? (
                            user.devices.map((device) => (
                                <Card.Link
                                    key={device.serialNumber}
                                    target="_blank"
                                    href="https://admin:dXQcznS4@dash.biodom27.si/d/NH0Rf-6Wz/heatingboiler?orgId=1&refresh=15m&var-SerialNumber=202054109&var-Topic=HeatingBoiler"
                                >
                                    {device.serialNumber}
                                </Card.Link>
                            ))) : ('')}
                        </ListGroupItem>
                    </ListGroup>
                    <Card.Footer>
                        <small className="text-muted">Last updated: {new Date(user.updatedAt).toUTCString()}, by {user.updatedBy}</small>
                    </Card.Footer>
                </Card>
            </Modal.Body>
        </Modal>
        </>
    );
}

export default View