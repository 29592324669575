import React, { useState } from 'react'
import { Col, Modal, Form, Button } from 'react-bootstrap'
import { BsPlus } from 'react-icons/bs';
import { useAuth } from "../../../services/authService";

const Add = (props) => {
    const TITLE     = "Add";
    const SUBMIT    = "Submit";
    const CANCEL    = "Cancel";

    const auth = useAuth();

    const initialDevice = {
        id: null,
        title: "",
        serialNumber: "",
        activated: true,
        updatedBy: auth.user.username,
        owner: auth.user.id
    }

    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => {
        setDevice(initialDevice)
        setShow(false)
    }

    const [device, setDevice] = useState(initialDevice)
    const [validated, setValidated] = useState(false);

    const handleInputChange = (event) => {
        const { name, value } = event.target
        setDevice({ ...device, [name]: value })
    }

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target
        setDevice({ ...device, [name]: checked })
    }

    const handleFormSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation()
            setValidated(true)
        } else {
            props.addDevice(device)
            setDevice(initialDevice)
            setValidated(false)
            handleClose()
        }
    }

    return (
        <>
        <Button variant="primary" size="sm" block onClick={handleShow}><BsPlus/></Button>

        <Modal show={show} onHide={handleClose} animation={false} backdrop="static" keyboard={false} id="neki">
            <Modal.Header closeButton>
                <Modal.Title>{TITLE}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="object-form" noValidate validated={validated} onSubmit={handleFormSubmit}>
                    <Form.Row>
                        <Form.Group as={Col} md="12" controlId="validationName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                name="title"
                                placeholder="Name..."
                                value={device.title}
                                onChange={handleInputChange}
                            />
                            <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">Looks bad</Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md="12" controlId="validationSerialNumber">
                            <Form.Label>Serial Number</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                name="serialNumber"
                                placeholder="Serial number..."
                                value={device.serialNumber}
                                onChange={handleInputChange}
                            />
                            <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">Looks bad</Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    <Form.Group>
                        <Form.Check
                            name="activated"
                            label="Activate"
                            onChange={handleCheckboxChange}
                            checked={device.activated}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button form="object-form" type="submit">{SUBMIT}</Button>
                {' '}
                <Button variant="secondary" onClick={handleClose}>{CANCEL}</Button>
            </Modal.Footer>
        </Modal>
        </>
    );
}

export default Add