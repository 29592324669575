import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert } from "react-bootstrap";
import PropTypes from 'prop-types';
import { alertService, AlertType } from "../services/alertService";

const propTypes = {
    id: PropTypes.string,
    fade: PropTypes.bool
};

const defaultProps = {
    id: 'default-alert',
    fade: true
};

function Notification({ id, fade }) {
    const history = useHistory();
    const [alerts, setAlerts] = useState([]);
    const timeout = 8000;

    useEffect(() => {
        // subscribe to new alert notifications
        const subscription = alertService.onAlert(id)
            .subscribe(alert => {
                // clear alerts when an empty alert is received
                if (!alert.message) {
                    setAlerts(alerts => {
                        // filter out alerts without 'keepAfterRouteChange' flag
                        const filteredAlerts = alerts.filter(x => x.keepAfterRouteChange);

                        // remove 'keepAfterRouteChange' flag on the rest
                        filteredAlerts.forEach(x => delete x.keepAfterRouteChange);
                        return filteredAlerts;
                    });
                } else {
                    setAlerts(alerts => ([...alerts, alert]));

                    if (alert.autoClose) {
                        setTimeout(() => removeAlert(alert), timeout);
                    }
                }
            });

        // clear alerts on location change
        const historyUnlisten = history.listen(({ pathname }) => {
            // don't clear if pathname has trailing slash because this will be auto redirected again
            if (pathname.endsWith('/')) return;
            alertService.clear(id);
        });

        // clean up function that runs when the component unmounts
        return () => {
            // unsubscribe & unlisten to avoid memory leaks
            subscription.unsubscribe();
            historyUnlisten();
        };
    }, []);

    function removeAlert(alert) {
        if (fade) {
            const alertWithFade = { ...alert, fade: true };
            setAlerts(alerts => alerts.map(x => x === alert ? alertWithFade : x));

            setTimeout(() => {
                setAlerts(alerts => alerts.filter(x => x !== alertWithFade));
            }, 250);
        } else {
            setAlerts(alerts => alerts.filter(x => x !== alert));
        }
    }

    function cssClasses(alert) {
        if (!alert) return;

        const classes = [];

        const alertTypeClass = {
            [AlertType.Success]: 'success',
            [AlertType.Error]: 'danger',
            [AlertType.Info]: 'info',
            [AlertType.Warning]: 'warning'
        }

        classes.push(alertTypeClass[alert.type]);

        if (alert.fade) {
            classes.push('fade');
        }

        return classes.join(' ');
    }

    if (!alerts.length) return null;

    return (
        alerts.map((alert, index) =>
            <Alert key={index} variant={cssClasses(alert)} onClose={() => removeAlert(alert)} dismissible>
                <p dangerouslySetInnerHTML={{__html: alert.message}}></p>
            </Alert>
        )
    );
}

Notification.propTypes = propTypes;
Notification.defaultProps = defaultProps;
export { Notification };