import { useState, useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { Ucfirst } from '../../../utils/capitalization';
import { DateNowIsoString } from '../../../utils/date';
import OnOff from '../../../components/switches/OnOff';
import Slider from '../../../components/sliders/Slider';
import { TemperatureToOneDecimal } from '../../../utils/conversion';
import Led from '../../../components/leds/Led';
import Select from '../../../components/select/Select';

export const AccumulatorTopic = {
    Receive: {
        PAR_AccMode: "PAR_AccMode",
        ACCStatus: "ACCStatus",
        PAR_DesiredAccTemp: "PAR_DesiredAccTemp",
        AUT: "AUT",
        ALT: "ALT",
        SupportBoilerIsON: "SupportBoilerIsON",
        BP1On: "BP1On"
    },
    Transmit: {
        PAR_AccMode: "cmd/Accumulator/PAR_AccMode",
        PAR_DesiredAccTemp: "cmd/Accumulator/PAR_DesiredAccTemp",
        SupportBoilerIsON: "cmd/Accumulator/SupportBoilerIsON",
        BP1On: "cmd/Accumulator/BP1On"
    }
}

const Accumulator = (props) => {
    const initialDevicesParam = {
        'ziggi': "Accumulator System",
        'data': [
            {'id': 1, 'name': "Operating Mode", 'key': "startStop", 'val': 0, 'unit': ""},
            {'id': 2, 'name': "Status", 'key': "status", 'val': "n/a", 'unit': ""},
            {'id': 3, 'name': "Desired Temperature", 'key': "desiredTemperature", 'val': 40, 'unit': "°C"},
            {'id': 4, 'name': "Upper T", 'key': "upperTemperature", 'val': 0, 'unit': "°C"},
            {'id': 5, 'name': "Lower T", 'key': "lowerTemperature", 'val': 0, 'unit': "°C"},
            {'id': 6, 'name': "Boiler", 'key': "boiler", 'val': false, 'unit': ""},
            {'id': 7, 'name': "BP1 Pump", 'key': "bp1Pump", 'val': false, 'unit': ""},
        ]
    }

    const ACCStatusEnum = { 0: "Off", 1: "Run", 240: "AUT", 241: "AUL" }

    const operationModeOptions = [
        { value: 0, label: "Off" },
        { value: 1, label: "Fixed" },
        { value: 2, label: "Adaptive" }
    ];

    const NAME = "Accumulator";
    const NO_DEVICE_PARAMETERS = "No device parameters";
    const [devicesParam] = useState(initialDevicesParam)

    const [parAccMode, setParAccMode] = useState(devicesParam.data[0].val);
    const [parAccModeTime, setParAccModeTime] = useState("n/a");
    const [accStatus, setAccStatus] = useState(devicesParam.data[1].val);
    const [accStatusTime, setAccStatusTime] = useState("n/a");
    const [parDesiredAccTemp, setParDesiredAccTemp] = useState(devicesParam.data[2].val);
    const [parDesiredAccTempTime, setParDesiredAccTempTime] = useState("n/a");
    const [aut, setAut] = useState(TemperatureToOneDecimal(devicesParam.data[3].val));
    const [autTime, setAutTime] = useState("n/a");
    const [alt, setAlt] = useState(TemperatureToOneDecimal(devicesParam.data[4].val));
    const [altTime, setAltTime] = useState("n/a");
    const [supportBoilerIsOn, setSupportBoilerIsOn] = useState(devicesParam.data[5].val);
    const [supportBoilerIsOnTime, setSupportBoilerIsOnTime] = useState("n/a");
    const [BP1On, setBP1On] = useState(devicesParam.data[6].val);
    const [BP1OnTime, setBP1OnTime] = useState("n/a");

    const onMessageReceive = (msg) => {
        var val = parseInt(msg.val);
        // console.log("RECIVE: "+msg.key+"="+msg.val)
        switch(msg.key) {
            case AccumulatorTopic.Receive.PAR_AccMode:         setParAccMode(val);                             setParAccModeTime(DateNowIsoString); break;
            case AccumulatorTopic.Receive.ACCStatus:           setAccStatus(ACCStatusEnum[val]);               setAccStatusTime(DateNowIsoString); break;
            case AccumulatorTopic.Receive.PAR_DesiredAccTemp:  setParDesiredAccTemp(val);                      setParDesiredAccTempTime(DateNowIsoString); break;
            case AccumulatorTopic.Receive.AUT:                 setAut(TemperatureToOneDecimal(val));           setAutTime(DateNowIsoString); break;
            case AccumulatorTopic.Receive.ALT:                 setAlt(TemperatureToOneDecimal(val));           setAltTime(DateNowIsoString); break;
            case AccumulatorTopic.Receive.SupportBoilerIsON:   setSupportBoilerIsOn(val === 1 ? true : false); setSupportBoilerIsOnTime(DateNowIsoString); break;
            case AccumulatorTopic.Receive.BP1On:               setBP1On(val === 1 ? true : false);             setBP1OnTime(DateNowIsoString); break;
            default:
                // console.log("Accumulator topic not found: " + msg.key);
                break;
        }
    };

    useEffect(() => {
        onMessageReceive(props.message);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.message]);

    const messageSend = (destination, message) => {
        props.onMessageSend(destination, message);
    }

    const onOperatingModeChange = (value) => {
        messageSend(AccumulatorTopic.Transmit.PAR_AccMode, value)
        // setParAccMode(value)
        // setParAccModeTime(DateNowIsoString)
    }

    const onTemperatureSliderChange = (value) => {
        let state = parseInt(value)
        messageSend(AccumulatorTopic.Transmit.PAR_DesiredAccTemp, state)
        setParDesiredAccTemp(state)
        setParDesiredAccTempTime(DateNowIsoString)
    }

    const onBoilerKnobChange = (value) => {
        let state = (value === true ? 1 : 2)
        messageSend(AccumulatorTopic.Transmit.SupportBoilerIsON, state)
        setSupportBoilerIsOn(!value)
        setSupportBoilerIsOnTime(DateNowIsoString)
    }

    return devicesParam.data.length > 0 ? (
        <>
        <Row>
            <Col className="mb-2" xs={12} sm={12} md={12} lg={12} key="title">
                <h4 className="text-secondary">{Ucfirst(NAME)}</h4>
            </Col>
        </Row>
        <Row>
            <Col className="mb-2" xs={6} sm={4} md={4} lg={3} key={devicesParam.data[0].id}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[0].name}</b></Card.Header>
                    <Card.Body style={{padding: "0.25rem", fontSize: "2.5rem"}}>
                        <Card.Text style={{padding: "0.7rem"}}>
                            <Select value={parAccMode} onChange={onOperatingModeChange} options={operationModeOptions} />
                        </Card.Text>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{parAccModeTime}</Card.Footer>
                </Card>
            </Col>
            <Col className="mb-2" xs={6} sm={4} md={4} lg={3} key={devicesParam.data[1].id}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[1].name}</b></Card.Header>
                    <Card.Body style={{padding: "0.62rem", fontSize: "2.0rem"}}>
                        <Card.Text style={{color: "bisque"}}>{accStatus}</Card.Text>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{accStatusTime}</Card.Footer>
                </Card>
            </Col>
            <Col className="mb-2" xs={12} sm={6} md={4} lg={3} key={devicesParam.data[2].id}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[2].name}</b></Card.Header>
                    <Card.Body style={{padding: "0", fontSize: "2.5rem"}}>
                        <Card.Text style={{color: "mediumaquamarine"}}>{parDesiredAccTemp}<small>{devicesParam.data[2].unit}</small></Card.Text>
                        <Slider value={parDesiredAccTemp} min={40} max={82} onChange={onTemperatureSliderChange}/>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{parDesiredAccTempTime}</Card.Footer>
                </Card>
            </Col>
            <Col className="mb-2" xs={6} sm={4} md={4} lg={3} key={devicesParam.data[3].id}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[3].name}</b></Card.Header>
                    <Card.Body style={{padding: "0.25rem", fontSize: "2.5rem"}}>
                        <Card.Text style={{color: aut > 50 ? "firebrick" : "cornflowerblue"}}>{aut}<small>{devicesParam.data[4].unit}</small></Card.Text>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{autTime}</Card.Footer>
                </Card>
            </Col>
            <Col className="mb-2" xs={6} sm={4} md={4} lg={3} key={devicesParam.data[4].id}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[4].name}</b></Card.Header>
                    <Card.Body style={{padding: "0.25rem", fontSize: "2.5rem"}}>
                        <Card.Text style={{color: alt > 50 ? "firebrick" : "cornflowerblue"}}>{alt}<small>{devicesParam.data[4].unit}</small></Card.Text>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{altTime}</Card.Footer>
                </Card>
            </Col>
            <Col className="mb-2" xs={6} sm={4} md={4} lg={3} key={devicesParam.data[5].id}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[5].name}</b></Card.Header>
                    <Card.Body style={{padding: "0.25rem", fontSize: "2.5rem"}}>
                        <OnOff value={supportBoilerIsOn} disabled={false} onChange={onBoilerKnobChange}/>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{supportBoilerIsOnTime}</Card.Footer>
                </Card>
            </Col>
            <Col className="mb-2" xs={6} sm={4} md={4} lg={3} key={devicesParam.data[6].id}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[6].name}</b></Card.Header>
                    <Card.Body style={{padding: "1.125rem", fontSize: "2.5rem"}}>
                        <Led value={BP1On}/>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{BP1OnTime}</Card.Footer>
                </Card>
            </Col>
            <Col className="mb-2" xs={6} sm={4} md={4} lg={3} key={devicesParam.data[5].id+"led"}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[5].name}</b></Card.Header>
                    <Card.Body style={{padding: "1.125rem", fontSize: "2.5rem"}}>
                        <Led value={supportBoilerIsOn}/>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{supportBoilerIsOnTime}</Card.Footer>
                </Card>
            </Col>
        </Row>
        </>
    ) : (
        <Row>
            <Col className="mb-2" xs={12} sm={12} md={12} lg={12}>
                <Card body bg="secondary" className="text-center">{NO_DEVICE_PARAMETERS}</Card>
            </Col>
        </Row>
    )
}

export default Accumulator