import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Form, Button } from "react-bootstrap";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useAuth } from "../../services/authService";
import { alertService } from "../../services/alertService";

const Register = (props) => {
    const history = useHistory();
    const auth = useAuth();

    const [submitting, setSubmitting] = useState(false);

    const initialValues = {
        email: '',
        password: '',
        confirmPassword: '',
        registerKey: '',
        acceptTerms: false
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('Required field')
            .email('Invalid Email address'),
        password: Yup.string()
            .required('Required field')
            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/, 'Must contain one uppercase, one lowercase and one number.')
            .min(8, 'Should have at least 8 chars'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Required field'),
        registerKey: Yup.string()
            .required('Required field')
            .min(36, 'Should have exactely 36 chars'),
        acceptTerms: Yup.bool()
            .oneOf([true], 'Accept Terms & Conditions is required')
    });

    useEffect(() => {
        props.componentTitle("Register");
    }, [props]);

    function onSubmit(values) {
        setSubmitting(true);
        auth.register(values)
            .then(() => {
                alertService.success('Please check your email '+values.email+' for verification instructions', { keepAfterRouteChange: true });
                history.push("login");
            })
            .catch(error => {
                setSubmitting(false);
                alertService.error(error);
            });
    }

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors
            }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group size="lg" controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            name="email"
                            value={values.email}
                            placeholder="john.doe@domain.com"
                            onChange={handleChange}
                            isInvalid={touched.email && errors.email}
                        />
                        <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group size="lg" controlId="password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            required
                            type="password"
                            name="password"
                            value={values.password}
                            placeholder="***************"
                            onChange={handleChange}
                            isInvalid={touched.password && errors.password}
                        />
                        <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group size="lg" controlId="confirm">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control
                            required
                            type="password"
                            name="confirmPassword"
                            value={values.confirmPassword}
                            placeholder="***************"
                            onChange={handleChange}
                            isInvalid={touched.confirmPassword && errors.confirmPassword}
                        />
                        <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">{errors.confirmPassword}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group size="lg" controlId="registerKey">
                        <Form.Label>Register Key</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            name="registerKey"
                            value={values.registerKey}
                            placeholder="***************"
                            onChange={handleChange}
                            isInvalid={touched.registerKey && errors.registerKey}
                        />
                        <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">{errors.registerKey}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Check
                            required
                            name="acceptTerms"
                            label="Accept Terms & Conditions"
                            onChange={handleChange}
                            isInvalid={errors.acceptTerms}
                            feedback={errors.acceptTerms}
                            checked={values.acceptTerms}
                        />
                    </Form.Group>

                    <Button block size="lg" type="submit" disabled={submitting}>
                        {submitting ? "Submitting..." : "Register"}
                    </Button>

                    <div className="text-center" style={{paddingTop: "1rem"}}>
                        <Link to="login" className="text-decoration-none text-secondary">Login</Link>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default Register;