import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Container } from 'react-bootstrap'

import { ProvideAuth } from './services/authService';
import PrivateRoute from './views/account/PrivateRoute';

import Header from './components/Header';
import { Account } from './views/account/Index';
import { Page } from './views/page/Index';
import { Admin } from './views/admin/Index';
import Error from './views/Error';
import Footer from './components/Footer';

function App() {
    return (
        <ProvideAuth>
            <BrowserRouter>
                <Header/>
                <Container className="mt-2 mb-3">
                    <Switch>
                        <Route path="/account" component={Account} />
                        <PrivateRoute path="/admin" component={Admin} />
                        <PrivateRoute path="/" component={Page} />
                        <Route component={Error} />
                    </Switch>
                </Container>
                <Footer/>
            </BrowserRouter>
        </ProvideAuth>
    );
}

export default App