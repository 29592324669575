import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/custom-overrides.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import reportWebVitals from './reportWebVitals';

ReactDOM.render(
    // <React.StrictMode>
        <App />
    // </React.StrictMode>
    ,document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.register();
const configuration = {
    onUpdate: (registration) => {
        if (registration && registration.waiting) {
            if (window.confirm('New version available! Refresh to update your app?')) {
              registration.waiting.postMessage({ type: 'SKIP_WAITING' });
              window.location.replace(process.env.REACT_APP_URL);
            }
        }
    }
};

serviceWorkerRegistration.register(configuration);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
