/**
 * Convert int to float one decimal
 * @param {int} value 
 * @returns 
 */
export const TemperatureToOneDecimal = (value) => {
    const stringValue = value.toString();
    const beforeDecimalPoint = stringValue.length > 1 ? stringValue.slice(0, stringValue.length - 1) : 0;
    const afterDecimalPoint = stringValue.slice(-1);
    return parseFloat(`${beforeDecimalPoint}.${afterDecimalPoint}`);
}