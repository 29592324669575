import React, { useState } from 'react'
import { Form, Button, Modal } from 'react-bootstrap'
import { BsPlus } from 'react-icons/bs';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { useAuth } from "../../../services/authService";
import { deviceService } from "../../../services/deviceService";
import { alertService } from "../../../services/alertService";
import { Crud } from "../../../helpers/crudAction";

const Add = (props) => {
    const TITLE = "Add";
    const SUBMIT = "Submit";
    const SUBMITTING = "Submitting";

    const auth = useAuth();

    const initialValues = {
        title: '',
        serialNumber: '',
        activated: true,
        owner: 1,
        operator: 0,
        updatedBy: auth.user.username
    };

    const [show, setShow] = useState(false)
    const [submitting, setSubmitting] = useState(false);
    const [entity, setEntity] = useState(initialValues);
    const handleShow = () => { setShow(true); }
    const handleClose = () => { setShow(false); }

    const validationSchema = Yup.object().shape({
        title: Yup.string().transform(value => (value === null ? '' : value)),
        serialNumber: Yup.string()
            .required('Required field')
            .min(8, 'Should have at least 8 chars')
            .max(32, 'Should have less thann 32 chars'),
        owner: Yup.number().required('Required field'),
        operator: Yup.number().nullable(),
        activated: Yup.bool()
    });

    function onSubmit(values) {
        setSubmitting(true);
        values.owner = parseInt(values.owner);
        values.operator = values.operator > 0 ? parseInt(values.operator) : null;
        deviceService.create(values)
            .then((response) => {
                setEntity(response);
                props.refreshList(response, Crud.ADD);
            })
            .catch(error => {
                alertService.error(error);
            })
            .finally(() => {
                setSubmitting(false);
                setEntity(initialValues);
                handleClose();
            });
    }

    return (
        <>
        <Button variant="success float-right" size="sm" onClick={handleShow}><BsPlus/></Button>

        <Modal show={show} onHide={handleClose} animation={false} backdrop="static" keyboard={false} id="add-modal">
            <Modal.Header closeButton id="add-modal-header">
                <Modal.Title>{TITLE}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik enableReinitialize={true} initialValues={entity} validationSchema={validationSchema} onSubmit={onSubmit}>
                    {({ handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        errors,
                    }) => (
                        <Form id="add-form" noValidate onSubmit={handleSubmit}>
                            <Form.Group size="lg" controlId="title">
                                <Form.Label>Title</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="title"
                                    value={values.title ?? ''}
                                    onChange={handleChange}
                                    isInvalid={touched.title && errors.title}
                                />
                                <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group size="lg" controlId="serialNumber">
                                <Form.Label>Serial Number</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="serialNumber"
                                    value={values.serialNumber ?? ''}
                                    onChange={handleChange}
                                    isInvalid={touched.serialNumber && errors.serialNumber}
                                />
                                <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{errors.serialNumber}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group size="lg" controlId="owner">
                                <Form.Label>Owner</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="owner"
                                    value={values.owner}
                                    onChange={handleChange}
                                    isInvalid={touched.owner && errors.owner}
                                >
                                    {props.users.map((user, i) => (
                                        <option key={i} value={user.id} label={user.email} />
                                    ))}
                                </Form.Control>
                                <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{errors.owner}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group size="lg" controlId="operator">
                                <Form.Label>Operator</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="operator"
                                    value={values.operator || 0}
                                    onChange={handleChange}
                                    isInvalid={touched.operator && errors.operator}
                                >
                                    <option key={0} value={0} label='...' />
                                    {props.operators.map((operator, i) => (
                                        <option key={i} value={operator.id} label={operator.email} />
                                    ))}
                                </Form.Control>
                                <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{errors.operator}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group size="lg" controlId="activated">
                                <Form.Check
                                    name="activated"
                                    label="Activated"
                                    onChange={handleChange}
                                    isInvalid={errors.activated}
                                    feedback={errors.activated}
                                    checked={values.activated}
                                />
                            </Form.Group>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
            <Modal.Footer>
                <Button form="add-form" type="submit" disabled={submitting}>
                    {submitting ? SUBMITTING+"..." : SUBMIT}
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    );
}

export default Add