import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap'
import { Ucfirst } from '../../utils/capitalization';

const Dashboard = () => {
    const NAME = "settings";
    const initialWidgets = [
        {id: 0, title: 'serials', path: 'serial'},
        {id: 1, title: 'unique keys', path: 'unique-key'},
        {id: 2, title: 'users', path: 'user'},
        {id: 3, title: 'devices', path: 'device'},
    ]

    const [widgets] = useState(initialWidgets);

    return (
        <>
        <Row>
            <Col>
                <h3 className="text-secondary">{Ucfirst(NAME)}</h3>
            </Col>
        </Row>
        <Row>
            {widgets.map((widget) => (
                <Col className="mb-2" xs={12} sm={6} md={4} lg={3} key={widget.id}>
                    <Button style={{textAlign: "left"}} variant="dark" size="lg" block as={Link} to={widget.path}>
                        {Ucfirst(widget.title)}
                    </Button>
                </Col>
            ))}
        </Row>
        </>
    )
}

export default Dashboard