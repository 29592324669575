import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button } from "react-bootstrap";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useAuth } from "../../services/authService";
import { alertService } from "../../services/alertService";

const ForgotPassword = (props) => {
    const auth = useAuth();

    const [submitting, setSubmitting] = useState(false);

    const initialValues = {
        email: ''
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('Required field')
            .email('Invalid Email address')
    });

    useEffect(() => {
        props.componentTitle("Forgot Password");
    }, [props]);

    function onSubmit(values) {
        setSubmitting(true);
        alertService.clear();
        auth.forgotPassword(values)
            .then(() => alertService.success('Please check your email '+values.email+' for verification instructions'))
            .catch(error => alertService.error(error))
            .finally(() => setSubmitting(false));
    }

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors
            }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group size="lg" controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                                    type="email"
                                    name="email"
                                    value={values.email}
                                    placeholder="john.doe@domain.com"
                                    onChange={handleChange}
                                    isInvalid={touched.email && errors.email}
                                />
                                <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                    </Form.Group>

                    <Button block size="lg" type="submit" disabled={submitting}>
                        {submitting ? "Submitting..." : "Submit"}
                    </Button>

                    <div className="text-center" style={{paddingTop: "1rem"}}>
                        <Link to="login" className="text-decoration-none text-secondary">Cancel</Link>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default ForgotPassword; 