import { axiosWrapper } from '../helpers/axiosWrapper';

const END_POINT = process.env.REACT_APP_BE_API_URL+"/serials"

const list = () =>              { return axiosWrapper.get(END_POINT, {}) };
const get = (id) =>             { return axiosWrapper.get(END_POINT+"/"+id, {}) };
const create = (data) =>        { return axiosWrapper.post(END_POINT, data) };
const update = (id, data) =>    { return axiosWrapper.put(END_POINT+"/"+id, data) };
const remove = (id) =>          { return axiosWrapper.delete(END_POINT+"/"+id) };

export const serialService = {
    list,
    get,
    create,
    update,
    remove
};