import React, { useState, useEffect } from "react";
import Switch from "react-switch";

const OnOff = (props) => {
    const [checked, setChecked] = useState(props.value)
    const [disabled] = useState(props.disabled)

    useEffect(() => {
        setChecked(props.value);
    }, [props.value]);

    const handleChange = (switchState) => {
        props.onChange(switchState)
        setChecked(switchState)
    }

    return (
        <Switch
            onChange={handleChange}
            checked={checked}
            disabled={disabled}
        />
    )
}

export default OnOff