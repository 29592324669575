import React, { useEffect } from 'react';
import { Route, Switch, useRouteMatch, useHistory } from 'react-router-dom';
import { useAuth } from "../../services/authService";
import { alertService } from "../../services/alertService";
import PrivateRoute from '../account/PrivateRoute';
import { Role } from "../../helpers/userRole";

import { Notification } from '../../components/Alert';
import Error from '../Error';
import Dashboard from './Dashboard';
import User from './User';
import Device from './Device';
import Serial from './Serial';
import UniqueKey from './UniqueKey';

function Admin() {
    const history = useHistory();
    const { path } = useRouteMatch();
    const auth = useAuth();

    useEffect(() => {
        if (!auth.user || ![Role.SUPER, Role.ADMIN, Role.OPERATOR].some(role => auth.user.roles.includes(role))) {
            alertService.warn('You are not authorized for this content', { keepAfterRouteChange: true });
            history.push('/');
        }
    }, [auth, history]);

    return (
        <>
            <Notification/>
            <Switch>
                <PrivateRoute path={path+"/dashboard"} component={Dashboard} />
                <PrivateRoute path={path+"/user"} component={User} />
                <PrivateRoute path={path+"/device"} component={Device} />
                <PrivateRoute path={path+"/serial"} component={Serial} />
                <PrivateRoute path={path+"/unique-key"} component={UniqueKey} />
                <Route component={Error} />
            </Switch>
        </>
    );
}

export { Admin };