import axios from "axios"

export const axiosWrapper = {
    get,
    post,
    put,
    patch,
    delete: remove
}

function get(url, parameters) {
    const options = {
        // headers: authHeader(),
        headers: {
            'Cache-Control': 'no-cache',
            ...authHeader()
        },
        params: parameters
    };
    return axios.get(url, options).then(handleResponse).catch(handleError);
}

function post(url, body) {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        }
    };
    return axios.post(url, body, options).then(handleResponse).catch(handleError);
}

function put(url, body) {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        }
    };
    return axios.put(url, body, options).then(handleResponse).catch(handleError);
}

function patch(url, body) {
    const options = {
        headers: {
            'Content-Type': 'application/merge-patch+json',
            ...authHeader()
        }
    };
    return axios.patch(url, body, options).then(handleResponse).catch(handleError);
}

function remove(url) {
    const options = {
        headers: authHeader()
    };
    return axios.delete(url, options).then(handleResponse).catch(handleError);
}


function handleResponse(response) {
    if (response.status < 200 && response.status > 299) {
        // TODO: auth service
        // if ([401, 403].includes(response.status) && accountService.userValue) {
        //     accountService.logout();
        // }
        if ([401, 403].includes(response.status)) {
            localStorage.setItem("token", null)
        }

        const error = (response.data && response.data.message) || response.statusText;
        return Promise.reject(error);
    }

    return response.data;
}

function handleError(error) {
    return Promise.reject(error.response ? error.response.data.message : error.message);
}

function authHeader() {
    // const auth = useAuth();
    // const user = auth.user;
    // return (user && user.token) ? user.token : null;

    // TODO: remove
    const token = localStorage.getItem('token');
    if (token && token.substring(0, 6) === "Bearer") {
        return { Authorization: token };
    } else {
        return { Authorization: null};
    }
}