import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useAuth } from "../../services/authService";
import { alertService } from "../../services/alertService";

import queryString from 'query-string';

const  VerifyEmail = (props) => {
    const history = useHistory();
    const location = useLocation();
    const auth = useAuth();

    const EmailStatus = {
        VERIFYING: 'Verifying',
        FAILED: 'Failed'
    }

    const [emailStatus] = useState(EmailStatus.VERIFYING);

    useEffect(() => {
        props.componentTitle("Email Verification");
        verifyEmail();
    }, []);

    const verifyEmail = () => {
        const token = queryString.parse(location.search);
        history.replace(location.pathname); // remove token from url to prevent http referer leakage

        auth.verifyEmail(token)
            .then(() => {
                alertService.success('Verification successful, you can now login', { keepAfterRouteChange: true });
                history.push('login');
            })
            .catch(() => {
                alertService.error(
                    'Verification failed, you can also verify your account using the <Link to="forgot-password">forgot password</Link> page.'
                );
            });
    }

    function getBody() {
        switch (emailStatus) {
            case EmailStatus.VERIFYING:
                return <p>Verifying...</p>;
            default:
            case EmailStatus.FAILED:
                return <p>Verification failed, you can also verify your account using the <Link to="forgot-password">forgot password</Link> page.</p>;
        }
    }

    return (
        getBody()
    )
}

export default VerifyEmail;