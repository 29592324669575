import React, { useState } from 'react'
import { Form, Button, Modal } from 'react-bootstrap'
import { BsPlus } from 'react-icons/bs';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { useAuth } from "../../../services/authService";
import { serialService } from "../../../services/serialService";
import { alertService } from "../../../services/alertService";
import { Crud } from "../../../helpers/crudAction";

const Add = (props) => {
    const TITLE = "Add";
    const SUBMIT = "Submit";
    const SUBMITTING = "Submitting";

    const auth = useAuth();

    const initialValues = {
        number: '',
        activated: true,
        updatedBy: auth.user.username
    };

    const [show, setShow] = useState(false)
    const [submitting, setSubmitting] = useState(false);
    const [entity, setEntity] = useState(initialValues);
    const handleShow = () => { setShow(true); }
    const handleClose = () => { setShow(false); }

    const validationSchema = Yup.object().shape({
        number: Yup.string()
            .required('Required field')
            .min(8, 'Should have at least 8 chars'),
        activated: Yup.bool()
    });

    function onSubmit(values) {
        setSubmitting(true);
        serialService.create(values)
            .then((response) => {
                setEntity(response);
                props.refreshList(response, Crud.ADD);
            })
            .catch(error => {
                alertService.error(error);
            })
            .finally(() => {
                setSubmitting(false);
                setEntity(initialValues);
                handleClose();
            });
    }

    return (
        <>
        <Button variant="success float-right" size="sm" onClick={handleShow}><BsPlus/></Button>

        <Modal show={show} onHide={handleClose} animation={false} backdrop="static" keyboard={false} id="add-modal">
            <Modal.Header closeButton id="add-modal-header">
                <Modal.Title>{TITLE}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik enableReinitialize={true} initialValues={entity} validationSchema={validationSchema} onSubmit={onSubmit}>
                    {({ handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        errors,
                    }) => (
                        <Form id="add-form" noValidate onSubmit={handleSubmit}>
                            <Form.Group size="lg" controlId="number">
                                <Form.Label>Serial number</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="number"
                                    value={values.number ?? ''}
                                    onChange={handleChange}
                                    isInvalid={touched.number && errors.number}
                                />
                                <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{errors.number}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group size="lg" controlId="activated">
                                <Form.Check
                                    name="activated"
                                    label="Activated"
                                    onChange={handleChange}
                                    isInvalid={errors.activated}
                                    feedback={errors.activated}
                                    checked={values.activated}
                                />
                            </Form.Group>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
            <Modal.Footer>
                <Button form="add-form" type="submit" disabled={submitting}>
                    {submitting ? SUBMITTING+"..." : SUBMIT}
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    );
}

export default Add