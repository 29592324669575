import React, { useState } from 'react'
import { Modal, Card, ListGroup, ListGroupItem, Button } from 'react-bootstrap'
import { BsX, BsCheck, BsCardText } from 'react-icons/bs';
import { Ucfirst } from '../../../utils/capitalization';

const View = (props) => {
    const TITLE = "serial";

    const [show, setShow] = useState(false)
    const [entity] = useState(props.item);
    const handleShow = () => { setShow(true); }
    const handleClose = () => { setShow(false); }

    return (
        <>
        <Button variant="primary float-right" size="sm" onClick={handleShow}><BsCardText/></Button>

        <Modal show={show} onHide={handleClose} animation={false} backdrop="static" keyboard={false} id="view-modal">
            <Modal.Header closeButton id="view-modal-header">
                <Modal.Title>{Ucfirst(TITLE)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Card>
                    <Card.Body>
                        <Card.Text><b>SN:</b> {entity.number}</Card.Text>
                    </Card.Body>
                    <ListGroup>
                        <ListGroupItem><b>ID:</b> {entity.id}</ListGroupItem>
                        <ListGroupItem>
                            <b>Activated:</b> {entity.activated ? (<BsCheck className="text-success"/>) : (<BsX className="text-danger"/>)}
                        </ListGroupItem>
                        <ListGroupItem><b>Created:</b> {entity.createdAt ? new Date(entity.createdAt).toUTCString() : "/"}</ListGroupItem>
                    </ListGroup>
                    <Card.Footer>
                        <small className="text-muted">Last updated: {new Date(entity.updatedAt).toUTCString()}, by {entity.updatedBy}</small>
                    </Card.Footer>
                </Card>
            </Modal.Body>
        </Modal>
        </>
    );
}

export default View