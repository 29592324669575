import React from 'react'
import { Container } from 'react-bootstrap'
import { BsExclamationTriangleFill } from 'react-icons/bs';

const E404 = () =>
    <Container>
        <div style={{color: "#adadad"}}>
            <h5><BsExclamationTriangleFill/> Sorry but we couldn't find this page.</h5>
            <p>The page you are looking for does not exist.</p>
        </div>
    </Container>

export default E404