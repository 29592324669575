import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { BsXCircle } from 'react-icons/bs'

const Remove = (props) => {
    const TITLE = "Remove";
    const TEXT  = "Are you sure you want to remove device";
    const YES   = "Yes";
    const NO    = "No";

    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    const handleRemove = () => {
        props.removeDevice(props.item.id)
        handleClose()
    }

    return (
        <>
        <Button className="float-right" variant="danger" size="sm" onClick={handleShow}><BsXCircle/></Button>

        <Modal show={show} onHide={handleClose} animation={false} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>{TITLE}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {TEXT} - "<b>{props.item.title}</b>"?
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleRemove}>{YES}</Button>
                {' '}
                <Button variant="secondary" onClick={handleClose}>{NO}</Button>
            </Modal.Footer>
        </Modal>
        </>
    );
}

export default Remove