import React, { useState, useEffect, useRef } from 'react'
import { Card } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

const CrudTable = (myProps) => {
    const { SearchBar } = Search;

    const DataStatus = {
        LOADING: 'Loading',
        DONE: 'Done',
        NONE: 'None'
    };

    const paginationOptions = {
        sizePerPage: 5,
        paginationSize: 3,
        pageStartIndex: 1,
        alwaysShowAllBtns: true,
        withFirstAndLast: true,
        showTotal: false,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
    };

    const mountedRef = useRef(true);
    const [dataStatus, setDataStatus] = useState(DataStatus.LOADING);

    useEffect(() => {
        if (!myProps.data) {
            setDataStatus(DataStatus.LOADING);
        } else if (myProps.data.length > 0) {
            setDataStatus(DataStatus.DONE);
        } else {
            setDataStatus(DataStatus.NONE);
        }
        return () => mountedRef.current = false;
    }, [myProps, DataStatus.LOADING, DataStatus.NONE, DataStatus.DONE]);

    function rowStyle(row, index) {
        row.index = index;
        const style = {};
        style.backgroundColor = (index % 2 === 0 ? '#3e444a' : '#343a40');
        style.color = '#ffffff';
        return style;
    }

    function indication() {
        var status = "";
        switch (dataStatus) {
            default:
            case DataStatus.NONE:       status = "No data"; break;
            case DataStatus.LOADING:    status = "Loading..."; break;
            case DataStatus.DONE:       status = ""; break;
        }

        return <Card body bg="secondary" className="text-center text-light">{status}</Card>;
    }

    return (
        <ToolkitProvider
            keyField = 'id'
            data = {myProps.data === null ? [] : myProps.data}
            columns = {myProps.columns}
            search
        >
            {props => (
                <div>
                    <SearchBar
                        { ...props.searchProps }
                        style = {{width: '100%'}}
                    />
                    <BootstrapTable
                        { ...props.baseProps }
                        keyField = 'id'
                        data = {myProps.data === null ? [] : myProps.data}
                        columns = {myProps.columns}
                        pagination = {paginationFactory(paginationOptions)}
                        headerClasses="table2-header"
                        rowStyle={rowStyle}
                        bordered = {false}
                        striped
                        hover
                        wrapperClasses="table-responsive"
                        noDataIndication = {indication}
                    />
                </div>
            )}
        </ToolkitProvider>
    )
}

export default CrudTable