import { useState, useEffect } from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
import { BsPlay, BsStop } from 'react-icons/bs';
import { Ucfirst } from '../../../utils/capitalization';
import { DateNowIsoString } from '../../../utils/date';
import Slider from '../../../components/sliders/Slider';
import { TemperatureToOneDecimal } from '../../../utils/conversion';
import Led from '../../../components/leds/Led';

export const SolarTopic = {
    Receive: {
        PAR_SolarMode: "Par_SolarMode",
        SolarStatus: "SolarStatus",
        Par_DesiredSolarDifference: "Par_DesiredSolarDifference",
        SPT: "SPT",
        SLT: "SLT",
        SPA: "SPA"
    },
    Transmit: {
        PAR_SolarMode: "cmd/SolarHeating/Par_SolarMode",
        Par_DesiredSolarDifference: "cmd/SolarHeating/Par_DesiredSolarDifference"
    }
}

const Solar = (props) => {
    const initialDevicesParam = {
        'ziggi': "Solar System",
        'data': [
            {'id': 1, 'name': "Start/Stop", 'key': "startStop", 'val': 0, 'unit': ""},
            {'id': 2, 'name': "Status", 'key': "status", 'val': "n/a", 'unit': ""},
            {'id': 3, 'name': "Desired Difference", 'key': "desiredDifference", 'val': 0, 'unit': ""},
            {'id': 4, 'name': "Panel T", 'key': "panelTemperature", 'val': 0, 'unit': "°C"},
            {'id': 5, 'name': "Spiral T", 'key': "spiralTemperature", 'val': 0, 'unit': "°C"},
            {'id': 6, 'name': "Pump", 'key': "pump", 'val': false, 'unit': ""}
        ]
    }

    const SolarStatusEnum = { 0: "Off", 1: "Run", 2: "Night", 240: "Roof", 241: "Spiral" }

    const NAME = "Solar";
    const NO_DEVICE_PARAMETERS = "No device parameters";
    const [devicesParam] = useState(initialDevicesParam)

    const [parSolarMode, setParSolarMode] = useState(devicesParam.data[0].val);
    const [parSolarModeTime, setParSolarModeTime] = useState("n/a");
    const [solarStatus, setSolarStatus] = useState(devicesParam.data[1].val);
    const [solarStatusTime, setSolarStatusTime] = useState("n/a");
    const [parDesiredSolarDifference, setParDesiredSolarDifference] = useState(devicesParam.data[2].val);
    const [parDesiredSolarDifferenceTime, setParDesiredSolarDifferenceTime] = useState("n/a");
    const [SPT, setSPT] = useState(TemperatureToOneDecimal(devicesParam.data[3].val));
    const [SPTTime, setSPTTime] = useState("n/a");
    const [SLT, setSLT] = useState(TemperatureToOneDecimal(devicesParam.data[4].val));
    const [SLTTime, setSLTTime] = useState("n/a");
    const [SPA, setSPA] = useState(devicesParam.data[5].val);
    const [SPATime, setSPATime] = useState("n/a");

    const onMessageReceive = (msg) => {
        var val = parseInt(msg.val);
        // console.log("RECIVE: "+msg.key+"="+msg.val)
        switch(msg.key) {
            case SolarTopic.Receive.PAR_SolarMode:               setParSolarMode(val);                   setParSolarModeTime(DateNowIsoString); break;
            case SolarTopic.Receive.SolarStatus:                 setSolarStatus(SolarStatusEnum[val]);   setSolarStatusTime(DateNowIsoString); break;
            case SolarTopic.Receive.Par_DesiredSolarDifference:  setParDesiredSolarDifference(val);      setParDesiredSolarDifferenceTime(DateNowIsoString); break;
            case SolarTopic.Receive.SPT:                         setSPT(TemperatureToOneDecimal(val));   setSPTTime(DateNowIsoString); break;
            case SolarTopic.Receive.SLT:                         setSLT(TemperatureToOneDecimal(val));   setSLTTime(DateNowIsoString); break;
            case SolarTopic.Receive.SPA:                         setSPA(val === 1 ? true : false);       setSPATime(DateNowIsoString); break;
            default:
                // console.log("Solar topic not found: " + msg.key);
                break;
        }
    }

    useEffect(() => {
        onMessageReceive(props.message);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const messageSend = (destination, message) => {
        props.onMessageSend(destination, message);
    }

    const onPowerButtonChange = () => {
        let state = (parSolarMode === 0 ? 1 : 0)
        messageSend(SolarTopic.Transmit.PAR_SolarMode, state)
        setParSolarMode(state)
        setParSolarModeTime(DateNowIsoString)
    }

    const onDifferenceSliderChange = (value) => {
        let state = parseInt(value)
        messageSend(SolarTopic.Transmit.Par_DesiredSolarDifference, state)
        setParDesiredSolarDifference(state)
        setParDesiredSolarDifferenceTime(DateNowIsoString)
    }

    return devicesParam.data.length > 0 ? (
        <>
        <Row>
            <Col className="mb-2" xs={12} sm={12} md={12} lg={12} key="title">
                <h4 className="text-secondary">{Ucfirst(NAME)}</h4>
            </Col>
        </Row>
        <Row>
            <Col className="mb-2" xs={6} sm={4} md={4} lg={3} key={devicesParam.data[0].id}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[0].name}</b></Card.Header>
                    <Card.Body style={{padding: "0.25rem", fontSize: "2.5rem"}}>
                        <Card.Text style={{padding: "0.7rem"}}>
                            <Button variant={parSolarMode === 0 ? "outline-success" : "outline-danger"} block onClick={onPowerButtonChange}>
                                {parSolarMode === 0 ? <BsPlay color="green"/> : <BsStop color="red"/>}
                            </Button>
                        </Card.Text>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{parSolarModeTime}</Card.Footer>
                </Card>
            </Col>
            <Col className="mb-2" xs={6} sm={4} md={4} lg={3} key={devicesParam.data[1].id}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[1].name}</b></Card.Header>
                    <Card.Body style={{padding: "0.62rem", fontSize: "2.0rem"}}>
                        <Card.Text style={{color: "bisque"}}>{solarStatus}</Card.Text>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{solarStatusTime}</Card.Footer>
                </Card>
            </Col>
            <Col className="mb-2" xs={12} sm={6} md={4} lg={3} key={devicesParam.data[2].id}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[2].name}</b></Card.Header>
                    <Card.Body style={{padding: "0", fontSize: "2.5rem"}}>
                        <Card.Text style={{color: "mediumaquamarine"}}>{parDesiredSolarDifference}</Card.Text>
                        <Slider value={parDesiredSolarDifference} min={0} max={99} onChange={onDifferenceSliderChange}/>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{parDesiredSolarDifferenceTime}</Card.Footer>
                </Card>
            </Col>
            <Col className="mb-2" xs={6} sm={4} md={4} lg={3} key={devicesParam.data[3].id}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[3].name}</b></Card.Header>
                    <Card.Body style={{padding: "0.25rem", fontSize: "2.5rem"}}>
                        <Card.Text style={{color: SPT > 50 ? "firebrick" : "cornflowerblue"}}>{SPT}<small>{devicesParam.data[4].unit}</small></Card.Text>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{SPTTime}</Card.Footer>
                </Card>
            </Col>
            <Col className="mb-2" xs={6} sm={4} md={4} lg={3} key={devicesParam.data[4].id}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[4].name}</b></Card.Header>
                    <Card.Body style={{padding: "0.25rem", fontSize: "2.5rem"}}>
                        <Card.Text style={{color: SLT > 50 ? "firebrick" : "cornflowerblue"}}>{SLT}<small>{devicesParam.data[4].unit}</small></Card.Text>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{SLTTime}</Card.Footer>
                </Card>
            </Col>
            <Col className="mb-2" xs={6} sm={4} md={4} lg={3} key={devicesParam.data[5].id}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[5].name}</b></Card.Header>
                    <Card.Body style={{padding: "1.125rem", fontSize: "2.5rem"}}>
                        <Led value={SPA}/>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{SPATime}</Card.Footer>
                </Card>
            </Col>
        </Row>
        </>
    ) : (
        <Row>
            <Col className="mb-2" xs={12} sm={12} md={12} lg={12}>
                <Card body bg="secondary" className="text-center">{NO_DEVICE_PARAMETERS}</Card>
            </Col>
        </Row>
    )
}

export default Solar