import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Form, Button } from "react-bootstrap";
import { Formik } from 'formik';
import * as Yup from 'yup';
import queryString from 'query-string';
import { useAuth } from "../../services/authService";
import { alertService } from "../../services/alertService";

const ResetPassword = (props) => {
    const history = useHistory();
    const location = useLocation();
    const auth = useAuth();

    const TokenStatus = {
        Validating: 'Validating',
        Valid: 'Valid',
        Invalid: 'Invalid'
    }

    const [submitting, setSubmitting] = useState(false);
    const [token, setToken] = useState(null);
    const [tokenStatus, setTokenStatus] = useState(TokenStatus.Validating);

    useEffect(() => {
        props.componentTitle("Reset Password");
        validateToken();
    }, []);

    const validateToken = () => {
        const queryToken = queryString.parse(location.search);
        history.replace(location.pathname); // remove token from url to prevent http referer leakage

        auth.validateResetToken(queryToken)
            .then(() => {
                setToken(queryToken);
                setTokenStatus(TokenStatus.Valid);
            })
            .catch(() => {
                setTokenStatus(TokenStatus.Invalid);
            });
    }

    function getForm() {
        const initialValues = {
            password: '',
            confirmPassword: ''
        };

        const validationSchema = Yup.object().shape({
            password: Yup.string()
                .required('Required field')
                .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/, 'Must contain one uppercase, one lowercase and one number.')
                .min(8, 'Should have at least 8 chars'),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
                .required('Required field')
        });

        function onSubmit(values) {
            alertService.clear();
            setSubmitting(true);
            auth.resetPassword({token: token.token, password: values.password, confirmPassword: values.confirmPassword, })
                .then(() => {
                    alertService.success('Password reset successful, you can now login', { keepAfterRouteChange: true });
                    history.push('login');
                })
                .catch(error => {
                    setSubmitting(false);
                    alertService.error(error);
                });
        }

        return (
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                {({ handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    isValid,
                    errors
                }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Form.Group size="lg" controlId="password">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control
                                required
                                type="password"
                                name="password"
                                value={values.password}
                                // placeholder="***************"
                                onChange={handleChange}
                                isInvalid={touched.password && errors.password}
                            />
                            <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group size="lg" controlId="confirm">
                            <Form.Label>Confirm New Password</Form.Label>
                            <Form.Control
                                required
                                type="password"
                                name="confirmPassword"
                                value={values.confirmPassword}
                                // placeholder="***************"
                                onChange={handleChange}
                                isInvalid={touched.confirmPassword && errors.confirmPassword}
                            />
                            <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">{errors.confirmPassword}</Form.Control.Feedback>
                        </Form.Group>
                        <Button block size="lg" type="submit" disabled={submitting}>
                            {submitting ? "Submitting..." : "Reset"}
                        </Button>

                        <div className="text-center" style={{paddingTop: "1rem"}}>
                            <Link to="login" className="text-decoration-none text-secondary">Cancel</Link>
                        </div>
                    </Form>
                )}
            </Formik>
        );
    }

    function getContent() {
        switch (tokenStatus) {
            case TokenStatus.Valid:
                return getForm();
            default:
            case TokenStatus.Invalid:
                return <p>Token validation failed, if the token has expired you can get a new one at the <Link to="forgot-password">forgot password</Link> page.</p>;
            case TokenStatus.Validating:
                return <p>Validating token...</p>;
        }
    }

    return (
        getContent()
    )
}

export default ResetPassword; 