import { useState, useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { Ucfirst } from '../../../utils/capitalization';
import { DateNowIsoString } from '../../../utils/date';
import Slider from '../../../components/sliders/Slider';
import Led from '../../../components/leds/Led';
import Select from '../../../components/select/Select';

export const HotWaterTopic = {
    Receive: {
        Par_HotWaterMode: "Par_HotWaterMode",
        HWStatus: "HWStatus",
        HWT: "HWT",
        HotWaterSw: "HotWaterSw",
        Par_DesiredHotWater: "Par_DesiredHotWater",
        HWP: "HWP"
    },
    Transmit: {
        Par_HotWaterMode: "cmd/HotWater/Par_HotWaterMode",
        Par_DesiredHotWater: "cmd/HotWater/Par_DesiredHotWater",
        HotWaterSw: "cmd/HotWater/HotWaterSw",
    }
}

const HotWater = (props) => {
    const initialDevicesParam = {
        'ziggi': "Hot Water System",
            'data': [
                {'id': 1, 'name': "Operating mode", 'key': "startStop", 'val': 0, 'unit': ""},
                {'id': 2, 'name': "Status", 'key': "status", 'val': "n/a", 'unit': ""},
                {'id': 3, 'name': "Desired Temperature", 'key': "desiredTemperature", 'val': 40, 'unit': "°C"},
                {'id': 4, 'name': "Desired Difference", 'key': "desiredDifference", 'val': 0, 'unit': ""},
                {'id': 5, 'name': "Thermostat", 'key': "thermostat", 'val': false, 'unit': ""},
                {'id': 6, 'name': "Pump", 'key': "pump", 'val': false, 'unit': "°C"}
            ]
    }

    const operationModeOptions = [
        { value: 0, label: "Off" },
        { value: 1, label: "Normal" },
        { value: 2, label: "Summer" }
    ];

    const NAME = "Hot Water";
    const NO_DEVICE_PARAMETERS = "No device parameters";
    const [devicesParam] = useState(initialDevicesParam)

    const HotWaterStatusEnum = { 0: "Off", 1: "Run", 240: "Sensor" }

    const [parHotWaterMode, setParHotWaterMode] = useState(devicesParam.data[0].val);
    const [parHotWaterModeTime, setParHotWaterModeTime] = useState("n/a");
    const [solarHWSTatus, setHWSTatus] = useState(devicesParam.data[1].val);
    const [solarHWSTatusTime, setHWSTatusTime] = useState("n/a");
    const [parDesiredHotWater, setParDesiredHotWater] = useState(devicesParam.data[2].val);
    const [parDesiredHotWaterTime, setParDesiredHotWaterTime] = useState("n/a");
    const [HWT, setHWT] = useState(devicesParam.data[3].val);
    const [HWTTime, setHWTTime] = useState("n/a");
    const [HotWaterSw, setHotWaterSw] = useState(devicesParam.data[4].val);
    const [HotWaterSwTime, setHotWaterSwTime] = useState("n/a");
    const [HWP, setHWP] = useState(devicesParam.data[5].val);
    const [HWPTime, setHWPTime] = useState("n/a");

    const onMessageReceive = (msg) => {
        var val = parseInt(msg.val);
        // console.log("RECIVE: "+msg.key+"="+msg.val)
        switch(msg.key) {
            case HotWaterTopic.Receive.Par_HotWaterMode:    setParHotWaterMode(val);                    setParHotWaterModeTime(DateNowIsoString); break;
            case HotWaterTopic.Receive.HWStatus:            setHWSTatus(HotWaterStatusEnum[val]);       setHWSTatusTime(DateNowIsoString); break;
            case HotWaterTopic.Receive.HWT:                 setHWT(val);                                setHWTTime(DateNowIsoString); break;
            case HotWaterTopic.Receive.HotWaterSw:          setHotWaterSw(val === 1 ? true : false);    setHotWaterSwTime(DateNowIsoString); break;
            case HotWaterTopic.Receive.Par_DesiredHotWater: setParDesiredHotWater(val);                 setParDesiredHotWaterTime(DateNowIsoString); break;
            case HotWaterTopic.Receive.HWP:                 setHWP(val === 1 ? true : false);           setHWPTime(DateNowIsoString); break;
            default:
                // console.log("Hot water topic not found: " + msg.key);
                break;
        }
    }

    useEffect(() => {
        onMessageReceive(props.message);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const messageSend = (destination, message) => {
        props.onMessageSend(destination, message);
    }

    const onOperatingModeChange = (value) => {
        messageSend(HotWaterTopic.Transmit.Par_HotWaterMode, value)
        // setParHotWaterMode(value)
        // setParHotWaterModeTime(DateNowIsoString)
    }

    const onHotWaterSliderChange = (value) => {
        let state = parseInt(value)
        messageSend(HotWaterTopic.Transmit.Par_DesiredHotWater, state)
        setParDesiredHotWater(state)
        setParDesiredHotWaterTime(DateNowIsoString)
    }

    return devicesParam.data.length > 0 ? (
        <>
        <Row>
            <Col className="mb-2" xs={12} sm={12} md={12} lg={12} key="title">
                <h4 className="text-secondary">{Ucfirst(NAME)}</h4>
            </Col>
        </Row>
        <Row>
            <Col className="mb-2" xs={6} sm={4} md={4} lg={3} key={devicesParam.data[0].id}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[0].name}</b></Card.Header>
                    <Card.Body style={{padding: "0.25rem", fontSize: "2.5rem"}}>
                        <Card.Text style={{padding: "0.7rem"}}>
                            <Select value={parHotWaterMode} onChange={onOperatingModeChange} options={operationModeOptions} />
                        </Card.Text>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{parHotWaterModeTime}</Card.Footer>
                </Card>
            </Col>
            <Col className="mb-2" xs={6} sm={4} md={4} lg={3} key={devicesParam.data[1].id}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[1].name}</b></Card.Header>
                    <Card.Body style={{padding: "0.62rem", fontSize: "2.0rem"}}>
                        <Card.Text style={{color: "bisque"}}>{solarHWSTatus}</Card.Text>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{solarHWSTatusTime}</Card.Footer>
                </Card>
            </Col>
            <Col className="mb-2" xs={12} sm={6} md={4} lg={3} key={devicesParam.data[2].id}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[2].name}</b></Card.Header>
                    <Card.Body style={{padding: "0", fontSize: "2.5rem"}}>
                        <Card.Text style={{color: "mediumaquamarine"}}>{parDesiredHotWater}<small>{devicesParam.data[2].unit}</small></Card.Text>
                        <Slider value={parDesiredHotWater} min={40} max={65} onChange={onHotWaterSliderChange}/>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{parDesiredHotWaterTime}</Card.Footer>
                </Card>
            </Col>
            <Col className="mb-2" xs={6} sm={4} md={4} lg={3} key={devicesParam.data[3].id}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[3].name}</b></Card.Header>
                    <Card.Body style={{padding: "0.25rem", fontSize: "2.5rem"}}>
                        <Card.Text style={{color: "mediumaquamarine"}}>{HWT}<small>{devicesParam.data[4].unit}</small></Card.Text>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{HWTTime}</Card.Footer>
                </Card>
            </Col>
            <Col className="mb-2" xs={6} sm={4} md={4} lg={3} key={devicesParam.data[4].id}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[4].name}</b></Card.Header>
                    <Card.Body style={{padding: "1.125rem", fontSize: "2.5rem"}}>
                        <Led value={HotWaterSw}/>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{HotWaterSwTime}</Card.Footer>
                </Card>
            </Col>
            <Col className="mb-2" xs={6} sm={4} md={4} lg={3} key={devicesParam.data[5].id}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[5].name}</b></Card.Header>
                    <Card.Body style={{padding: "1.125rem", fontSize: "2.5rem"}}>
                        <Led value={HWP}/>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{HWPTime}</Card.Footer>
                </Card>
            </Col>
        </Row>
        </>
    ) : (
        <Row>
            <Col className="mb-2" xs={12} sm={12} md={12} lg={12}>
                <Card body bg="secondary" className="text-center">{NO_DEVICE_PARAMETERS}</Card>
            </Col>
        </Row>
    )
}

export default HotWater