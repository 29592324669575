import { axiosWrapper } from '../helpers/axiosWrapper';

const END_POINT = process.env.REACT_APP_BE_API_URL+"/users"

const list = () =>              { return axiosWrapper.get(END_POINT, {}) };
const get = (id) =>             { return axiosWrapper.get(END_POINT+"/"+id, {}) };
const create = (data) =>        { return axiosWrapper.post(END_POINT, data) };
const update = (id, data) =>    { return axiosWrapper.put(END_POINT+"/"+id, data) };
const remove = (id) =>          { return axiosWrapper.delete(END_POINT+"/"+id) };

const getByEmail = (email) =>   { return axiosWrapper.get(END_POINT+"/by_email", {email: email}) };
const listWithDevices = () =>   { return axiosWrapper.get(END_POINT+"/with_devices", {}) };
const listOperators = () =>     { return axiosWrapper.get(END_POINT+"/operators", {}) };

export const userService = {
    list,
    get,
    create,
    update,
    remove,
    getByEmail,
    listWithDevices,
    listOperators
};