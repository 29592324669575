import React, {useState, useEffect} from 'react';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import RangeSlider from 'react-bootstrap-range-slider';

const Slider = (props) => {
    const [min] = useState(props.min)
    const [max] = useState(props.max)
    const [value, setValue] = useState(props.value)
    const [disabled] = useState(props.disabled)

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const changeValue = (event) => {
        setValue(event.target.value)
    }

    const handleChange = (event) => {
        props.onChange(event.target.value)
    }

    return (
        <RangeSlider
            value={value}
            onChange={changeValue}
            onAfterChange={handleChange}
            min={min}
            max={max}
            size={'lg'}
            variant={'info'}
            tooltip={'on'}
            tooltipPlacement={'top'}
            disabled={disabled}
        />
    )
}

export default Slider