import React, { useState, useEffect, useRef } from 'react'
import { Row, Col, ButtonGroup } from 'react-bootstrap'
import { deviceService } from '../../services/deviceService';
import { userService } from "../../services/userService";
import { useAuth } from "../../services/authService";
import { alertService } from "../../services/alertService";
import { Ucfirst } from '../../utils/capitalization';
import { Crud } from "../../helpers/crudAction";
import { Role } from "../../helpers/userRole";

import CrudTable from '../../components/tables/CrudTable';
import ModalView from '../../components/modals/devicer/View';
import ModalAdd from '../../components/modals/devicer/Add';
import ModalEdit from '../../components/modals/devicer/Edit';
import ModalRemove from '../../components/modals/devicer/Remove';

const Device = () => {
    const NAME = "device";
    const mountedRef = useRef(true);
    const auth = useAuth();
    const [entities, setEntities] = useState(null);
    const [operators, setOperators] = useState([]);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        listUsers();
        return () => mountedRef.current = false;
    }, [auth]);

    const listEntities = (operatorId) => {
        deviceService.listByOperatorId(operatorId)
            .then(response => {
                setEntities(response);
            })
            .catch(error => {
                alertService.error(error);
                setEntities([]);
            });
    }

    const listUsers = () => {
        userService.list()
            .then(response => {
                setUsers(response);
                setOperators(response.filter(user => user.roles.includes(Role.OPERATOR)));
            })
            .catch(error => {
                alertService.error(error);
                setUsers([]);
                setOperators([]);
            })
            .finally(() => {
                listEntities([Role.OPERATOR].some(role => auth.user.roles.includes(role)) ? auth.user.id : null);
            });
    }

    const tableColumns = [
        {
            dataField: 'serialNumber',
            text: 'Serial'
        },
        {
            dataField: 'ownerEmail',
            text: 'Owner'
        },
        {
            dataField: 'manage',
            text: 'Manage',
            headerAlign: 'right',
            align: 'right',
            formatter: manageFormatter,
        }
    ];

    function manageFormatter(cell, row) {
        if (![Role.SUPER, Role.ADMIN].some(role => auth.user.roles.includes(role))) {
            return <ButtonGroup>
                    <ModalView item={row} />
                </ButtonGroup>
        } else {
            return <ButtonGroup>
                    <ModalEdit item={row} operators={operators} refreshList={refreshList} />
                    <ModalRemove item={row} refreshList={refreshList} />
                </ButtonGroup>
        }
    }

    function refreshList(object, action) {
        switch (action) {
            case Crud.ADD:
                setEntities([...entities, object]);
                alertService.success(Ucfirst(NAME) + ' created successfully');
                break;

            case Crud.EDIT:
                setEntities(entities.map((entity) => (entity.id === object.id ? object : entity)));
                alertService.success(Ucfirst(NAME) + ' updated successfully');
                break;

            case Crud.REMOVE:
                setEntities(entities.filter((entity) => entity.id !== object));
                alertService.success(Ucfirst(NAME) + ' removed successfully');
                break;

            default:
                alertService.warn('Something went wrong');
                break;
        }
    }

    return (
        <>
        <Row>
            <Col>
                <h3 className="text-secondary">{Ucfirst(NAME)}s 
                    <ModalAdd operators={operators} users={users} refreshList={refreshList} />
                </h3>
            </Col>
        </Row>
        <Row>
            <Col>
                <CrudTable name={NAME} data={entities} columns={tableColumns} />
            </Col>
        </Row>
        </>
    )
}

export default Device