import { useState, useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { Ucfirst } from '../../../utils/capitalization';
import { DateNowIsoString } from '../../../utils/date';
import Slider from '../../../components/sliders/Slider';
import { TemperatureToOneDecimal } from '../../../utils/conversion';
import Led from '../../../components/leds/Led';
import Select from '../../../components/select/Select';

export const HeatingCircleThreeTopic = {
    Receive: {
        Par_HCV3_Mode: "Par_HCV3_Mode",
        HC3STS: "HC3STS",
        Par_HCV3WeatherCurve: "Par_HCV3WeatherCurve",
        HC3DT: "HC3DT",
        HC3T: "HC3T",
        HC3R: "HC3R",
        HC3P: "HC3P"
    },
    Transmit: {
        Par_HCV3_Mode: "cmd/HeatingCircle3/Par_HCV3_Mode",
        Par_HCV3WeatherCurve: "cmd/HeatingCircle3/Par_HCV3WeatherCurve",
        HC3DT: "cmd/HeatingCircle3/HC3DT"
    }
}

const HeatingCircleThree = (props) => {
    const initialDevicesParam = {
        'ziggi': "Heating Circle 3",
        'data': [
            {'id': 1, 'name': "Operating Mode", 'key': "startStop", 'val': 0, 'unit': ""},
            {'id': 2, 'name': "Status", 'key': "status", 'val': "n/a", 'unit': ""},
            {'id': 3, 'name': "Heating Curve", 'key': "heatingCurve", 'val': 0, 'unit': ""},
            {'id': 4, 'name': "Desired Temperature", 'key': "desiredTemperature", 'val': 0, 'unit': "°C"},
            {'id': 5, 'name': "Water T", 'key': "waterTemperature", 'val': 0, 'unit': "°C"},
            {'id': 6, 'name': "Room Thermostat", 'key': "roomThermostat", 'val': false, 'unit': "°C"},
            {'id': 7, 'name': "Water Pump", 'key': "waterPump", 'val': false, 'unit': ""}
        ]
    }

    const CircleStatusEnum = { 0: "Off", 1: "Weat.", 2: "Ther.", 3: "Weat.+", 10: "HePu", 240: "HCx" }

    const operationModeOptions = [
        { value: 0, label: "Off" },
        { value: 1, label: "Weather" },
        { value: 2, label: "Thermostat" },
        { value: 3, label: "Advanced" }
    ];

    const NAME = "Heating Circle 3";
    const NO_DEVICE_PARAMETERS = "No device parameters";
    const [devicesParam] = useState(initialDevicesParam)

    const [parHCV3Mode, setParHCV3Mode] = useState(devicesParam.data[0].val);
    const [parHCV3ModeTime, setParHCV3ModeTime] = useState("n/a");
    const [HC3STS, setHC3STS] = useState(devicesParam.data[1].val);
    const [HC3STSTime, setHC3STSTime] = useState("n/a");
    const [HCV3WeatherCurve, setHCV3WeatherCurve] = useState(devicesParam.data[2].val);
    const [HCV3WeatherCurveTime, setHCV3WeatherCurveTime] = useState("n/a");
    const [HC3DT, setHC3DT] = useState(devicesParam.data[3].val);
    const [HC3DTTime, setHC3DTTime] = useState("n/a");
    const [HC3T, setHC3T] = useState(TemperatureToOneDecimal(devicesParam.data[4].val));
    const [HC3TTime, setHC3TTime] = useState("n/a");
    const [HC3R, setHC3R] = useState(devicesParam.data[5].val);
    const [HC3RTime, setHC3RTime] = useState("n/a");
    const [HC3P, setHC3P] = useState(devicesParam.data[6].val);
    const [HC3PTime, setHC3PTime] = useState("n/a");

    const onMessageReceive = (msg) => {
        var val = parseInt(msg.val);
        // console.log("RECIVE: "+msg.key+"="+msg.val)
        switch(msg.key) {
            case HeatingCircleThreeTopic.Receive.Par_HCV3_Mode:           setParHCV3Mode(val);                      setParHCV3ModeTime(DateNowIsoString); break;
            case HeatingCircleThreeTopic.Receive.HC3STS:                  setHC3STS(CircleStatusEnum[val]);         setHC3STSTime(DateNowIsoString); break;
            case HeatingCircleThreeTopic.Receive.Par_HCV3WeatherCurve:    setHCV3WeatherCurve(val);                 setHCV3WeatherCurveTime(DateNowIsoString); break;
            case HeatingCircleThreeTopic.Receive.HC3DT:                   setHC3DT(val);                            setHC3DTTime(DateNowIsoString); break;
            case HeatingCircleThreeTopic.Receive.HC3T:                    setHC3T(TemperatureToOneDecimal(val));    setHC3TTime(DateNowIsoString); break;
            case HeatingCircleThreeTopic.Receive.HC3R:                    setHC3R(val === 0 ? true : false);        setHC3RTime(DateNowIsoString); break;
            case HeatingCircleThreeTopic.Receive.HC3P:                    setHC3P(val === 1 ? true : false);        setHC3PTime(DateNowIsoString); break;
            default:
                // console.log("Heating circle 3 topic not found: " + msg.key);
                break;
        }
    }

    useEffect(() => {
        onMessageReceive(props.message);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const messageSend = (destination, message) => {
        props.onMessageSend(destination, message);
    }

    const onOperatingModeChange = (value) => {
        messageSend(HeatingCircleThreeTopic.Transmit.Par_HCV3_Mode, value)
        // setParHCV3Mode(value)
        // setParHCV3ModeTime(DateNowIsoString)
    }

    const onHeatingSliderChange = (value) => {
        let state = parseInt(value)
        messageSend(HeatingCircleThreeTopic.Transmit.Par_HCV3WeatherCurve, state)
        setHCV3WeatherCurve(state)
        setHCV3WeatherCurveTime(DateNowIsoString)
    }

    const onTemperatureSliderChange = (value) => {
        let state = parseInt(value)
        messageSend(HeatingCircleThreeTopic.Transmit.HC3DT, state)
        setHC3DT(state)
        setHC3DTTime(DateNowIsoString)
    }

    return devicesParam.data.length > 0 ? (
        <>
        <Row>
            <Col className="mb-2" xs={12} sm={12} md={12} lg={12} key="title">
                <h4 className="text-secondary">{Ucfirst(NAME)}</h4>
            </Col>
        </Row>
        <Row>
            <Col className="mb-2" xs={6} sm={4} md={4} lg={3} key={devicesParam.data[0].id}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[0].name}</b></Card.Header>
                    <Card.Body style={{padding: "0.25rem", fontSize: "2.5rem"}}>
                        <Card.Text style={{padding: "0.7rem"}}>
                            <Select value={parHCV3Mode} onChange={onOperatingModeChange} options={operationModeOptions} />
                        </Card.Text>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{parHCV3ModeTime}</Card.Footer>
                </Card>
            </Col>
            <Col className="mb-2" xs={6} sm={4} md={4} lg={3} key={devicesParam.data[1].id}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[1].name}</b></Card.Header>
                    <Card.Body style={{padding: "0.62rem", fontSize: "2.0rem"}}>
                        <Card.Text style={{color: "bisque"}}>{HC3STS}</Card.Text>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{HC3STSTime}</Card.Footer>
                </Card>
            </Col>
            <Col className="mb-2" xs={12} sm={6} md={4} lg={3} key={devicesParam.data[2].id}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[2].name}</b></Card.Header>
                    <Card.Body style={{padding: "0", fontSize: "2.5rem"}}>
                        <Card.Text style={{color: "mediumaquamarine"}}>{HCV3WeatherCurve}<small>{devicesParam.data[2].unit}</small></Card.Text>
                        <Slider value={HCV3WeatherCurve} min={0} max={29} onChange={onHeatingSliderChange}/>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{HCV3WeatherCurveTime}</Card.Footer>
                </Card>
            </Col>
            <Col className="mb-2" xs={12} sm={6} md={4} lg={3} key={devicesParam.data[3].id}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[3].name}</b></Card.Header>
                    <Card.Body style={{padding: "0", fontSize: "2.5rem"}}>
                        <Card.Text style={{color: "mediumaquamarine"}}>{HC3DT}<small>{devicesParam.data[3].unit}</small></Card.Text>
                        <Slider value={HC3DT} disabled={HCV3WeatherCurve === 0 ? true : false} min={0} max={100} onChange={onTemperatureSliderChange}/>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{HC3DTTime}</Card.Footer>
                </Card>
            </Col>
            <Col className="mb-2" xs={6} sm={4} md={4} lg={3} key={devicesParam.data[4].id}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[4].name}</b></Card.Header>
                    <Card.Body style={{padding: "0.25rem", fontSize: "2.5rem"}}>
                        <Card.Text style={{color: HC3T > 50 ? "firebrick" : "cornflowerblue"}}>{HC3T}<small>{devicesParam.data[4].unit}</small></Card.Text>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{HC3TTime}</Card.Footer>
                </Card>
            </Col>
            <Col className="mb-2" xs={6} sm={4} md={4} lg={3} key={devicesParam.data[5].id}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[5].name}</b></Card.Header>
                    <Card.Body style={{padding: "1.125rem", fontSize: "2.5rem"}}>
                        <Led value={HC3R}/>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{HC3RTime}</Card.Footer>
                </Card>
            </Col>
            <Col className="mb-2" xs={6} sm={4} md={4} lg={3} key={devicesParam.data[6].id}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[6].name}</b></Card.Header>
                    <Card.Body style={{padding: "1.125rem", fontSize: "2.5rem"}}>
                        <Led value={HC3P}/>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{HC3PTime}</Card.Footer>
                </Card>
            </Col>
        </Row>
        </>
    ) : (
        <Row>
            <Col className="mb-2" xs={12} sm={12} md={12} lg={12}>
                <Card body bg="secondary" className="text-center">{NO_DEVICE_PARAMETERS}</Card>
            </Col>
        </Row>
    )
}

export default HeatingCircleThree