import React, { useState, useEffect, useContext, createContext } from "react";
import { axiosWrapper } from '../helpers/axiosWrapper';

const BASE_URL = process.env.REACT_APP_BE_AUTH_URL;
const authContext = createContext();

// Provider component that wraps your app and makes auth object
// available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object
// and re-render when it changes.
export const useAuth = () => {
    return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
    const [user, setUser] = useState(null);
    let refreshTokenTimeout;

    const register = (values) => {
        return axiosWrapper.post(BASE_URL+"/register", values);
    };

    const verifyEmail = (token) => {
        return axiosWrapper.post(BASE_URL+"/verify_email", token);
    }

    const login = (values) => {
        return axiosWrapper.post(BASE_URL+"/login", values)
            .then(response => {
                updateAuthentication(response);
                return 0;
            })
            .catch(error => {
                return Promise.reject(error);
            });
    };

    const forgotPassword = (values) => {
        return axiosWrapper.post(BASE_URL+"/forgot_password", values);
    };

    const validateResetToken = (token) => {
        return axiosWrapper.post(BASE_URL+"/validate_reset_token", token);
    };
    
    const resetPassword = (values) => {
        return axiosWrapper.post(BASE_URL+"/reset_password", values);
    };

    const logout = () => {
        setUser(false);
        // stopRefreshTokenTimer();
        // TODO: remove
        localStorage.setItem("token", null)
        // axiosWrapper.post(BASE_URL+"/logout", {});
        // history.push('/account/login');
    };

    const refreshToken = () => {
        console.log("TIMEOUT...");
        // axiosWrapper.post(BASE_URL+"/refresh-token", {}).then(startRefreshTokenTimer());
    }

    // Subscribe to user on mount
    // Because this sets state in the callback it will cause any component
    // that utilizes this hook to re-render with the latest auth object.
    useEffect(() => {
    //     const unsubscribe = firebase.auth().onAuthStateChanged(user => {
    //         if (user) {
    //             setUser(user);
    //         } else {
    //             setUser(false);
    //         }
    //     });

    //     // Cleanup subscription on unmount
    //     return () => unsubscribe();
    }, []);

    function updateAuthentication(data) {
        const jwtToken = JSON.parse(atob((data.token.split('.')[1])));
        // startRefreshTokenTimer(jwtToken.exp);

        // TODO: remove
        localStorage.setItem("token", "Bearer "+data.token)

        setUser({
            token: "Bearer "+data.token,
            refreshToken: data.refreshToken,
            id: jwtToken.user.id,
            username: jwtToken.user.email,
            roles: jwtToken.user.roles
        });
    }

    function startRefreshTokenTimer(jwtTokenExp) {
        const expires = new Date(jwtTokenExp * 1000);
        const timeout = expires.getTime() - Date.now() - (60 * 1000);
        refreshTokenTimeout = setTimeout(refreshToken, timeout);
    }

    function stopRefreshTokenTimer() {
        clearTimeout(refreshTokenTimeout);
    }

    // Return the user object and auth methods
    return {
        user,
        register,
        verifyEmail,
        login,
        refreshToken,
        forgotPassword,
        validateResetToken,
        resetPassword,
        logout,
    };
}