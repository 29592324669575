import { useState, useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { Ucfirst } from '../../../utils/capitalization';
import { DateNowIsoString } from '../../../utils/date';
import Slider from '../../../components/sliders/Slider';
import { TemperatureToOneDecimal } from '../../../utils/conversion';
import Led from '../../../components/leds/Led';
import Select from '../../../components/select/Select';

export const HeatingCircleOneTopic = {
    Receive: {
        Par_HCV1_Mode: "Par_HCV1_Mode",
        HC1STS: "HC1STS",
        Par_HCV1WeatherCurve: "Par_HCV1WeatherCurve",
        HC1DT: "HC1DT",
        HC1T: "HC1T",
        HC1R: "HC1R",
        HC1P: "HC1P"
    },
    Transmit: {
        Par_HCV1_Mode: "cmd/HeatingCircle1/Par_HCV1_Mode",
        Par_HCV1WeatherCurve: "cmd/HeatingCircle1/Par_HCV1WeatherCurve",
        HC1DT: "cmd/HeatingCircle1/HC1DT"
    }
}

const HeatingCircleOne = (props) => {
    const initialDevicesParam = {
        'ziggi': "Heating Circle 1",
        'data': [
            {'id': 1, 'name': "Operating mode", 'key': "startStop", 'val': 0, 'unit': ""},
            {'id': 2, 'name': "Status", 'key': "status", 'val': "n/a", 'unit': ""},
            {'id': 3, 'name': "Heating Curve", 'key': "heatingCurve", 'val': 0, 'unit': ""},
            {'id': 4, 'name': "Desired Temperature", 'key': "desiredTemperature", 'val': 0, 'unit': "°C"},
            {'id': 5, 'name': "Water T", 'key': "waterTemperature", 'val': 0, 'unit': "°C"},
            {'id': 6, 'name': "Room Thermostat", 'key': "roomThermostat", 'val': false, 'unit': "°C"},
            {'id': 7, 'name': "Water Pump", 'key': "waterPump", 'val': false, 'unit': ""}
        ]
    }

    const CircleStatusEnum = { 0: "Off", 1: "Weat.", 2: "Ther.", 3: "Weat.+", 240: "HCx" }

    const operationModeOptions = [
        { value: 0, label: "Off" },
        { value: 1, label: "Weather" },
        { value: 2, label: "Thermostat" },
        { value: 3, label: "Advanced" }
    ];

    const NAME = "Heating Circle 1";
    const NO_DEVICE_PARAMETERS = "No device parameters";
    const [devicesParam] = useState(initialDevicesParam)

    const [parHCV1Mode, setParHCV1Mode] = useState(devicesParam.data[0].val);
    const [parHCV1ModeTime, setParHCV1ModeTime] = useState("n/a");
    const [HC1STS, setHC1STS] = useState(devicesParam.data[1].val);
    const [HC1STSTime, setHC1STSTime] = useState("n/a");
    const [HCV1WeatherCurve, setHCV1WeatherCurve] = useState(devicesParam.data[2].val);
    const [HCV1WeatherCurveTime, setHCV1WeatherCurveTime] = useState("n/a");
    const [HC1DT, setHC1DT] = useState(devicesParam.data[3].val);
    const [HC1DTTime, setHC1DTTime] = useState("n/a");
    const [HC1T, setHC1T] = useState(TemperatureToOneDecimal(devicesParam.data[4].val));
    const [HC1TTime, setHC1TTime] = useState("n/a");
    const [HC1R, setHC1R] = useState(devicesParam.data[5].val);
    const [HC1RTime, setHC1RTime] = useState("n/a");
    const [HC1P, setHC1P] = useState(devicesParam.data[6].val);
    const [HC1PTime, setHC1PTime] = useState("n/a");

    const onMessageReceive = (msg) => {
        var val = parseInt(msg.val);
        // console.log("RECIVE: "+msg.key+"="+msg.val)
        switch(msg.key) {
            case HeatingCircleOneTopic.Receive.Par_HCV1_Mode:           setParHCV1Mode(val);                    setParHCV1ModeTime(DateNowIsoString); break;
            case HeatingCircleOneTopic.Receive.HC1STS:                  setHC1STS(CircleStatusEnum[val]);       setHC1STSTime(DateNowIsoString); break;
            case HeatingCircleOneTopic.Receive.Par_HCV1WeatherCurve:    setHCV1WeatherCurve(val);               setHCV1WeatherCurveTime(DateNowIsoString); break;
            case HeatingCircleOneTopic.Receive.HC1DT:                   setHC1DT(val);                          setHC1DTTime(DateNowIsoString); break;
            case HeatingCircleOneTopic.Receive.HC1T:                    setHC1T(TemperatureToOneDecimal(val));  setHC1TTime(DateNowIsoString); break;
            case HeatingCircleOneTopic.Receive.HC1R:                    setHC1R(val === 0 ? true : false);      setHC1RTime(DateNowIsoString); break;
            case HeatingCircleOneTopic.Receive.HC1P:                    setHC1P(val === 1 ? true : false);      setHC1PTime(DateNowIsoString); break;
            default:
                // console.log("Heating circle 1 topic not found: " + msg.key);
                break;
        }
    }

    useEffect(() => {
        onMessageReceive(props.message);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const messageSend = (destination, message) => {
        props.onMessageSend(destination, message);
    }

    const onOperatingModeChange = (value) => {
        messageSend(HeatingCircleOneTopic.Transmit.Par_HCV1_Mode, value)
        // setParHCV1Mode(value)
        // setParHCV1ModeTime(DateNowIsoString)
    }

    const onHeatingSliderChange = (value) => {
        let state = parseInt(value)
        messageSend(HeatingCircleOneTopic.Transmit.Par_HCV1WeatherCurve, state)
        setHCV1WeatherCurve(state)
        setHCV1WeatherCurveTime(DateNowIsoString)
    }

    const onTemperatureSliderChange = (value) => {
        let state = parseInt(value)
        messageSend(HeatingCircleOneTopic.Transmit.HC1DT, state)
        setHC1DT(state)
        setHC1DTTime(DateNowIsoString)
    }

    return devicesParam.data.length > 0 ? (
        <>
        <Row>
            <Col className="mb-2" xs={12} sm={12} md={12} lg={12} key="title">
                <h4 className="text-secondary">{Ucfirst(NAME)}</h4>
            </Col>
        </Row>
        <Row>
            <Col className="mb-2" xs={6} sm={4} md={4} lg={3} key={devicesParam.data[0].id}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[0].name}</b></Card.Header>
                    <Card.Body style={{padding: "0.25rem", fontSize: "2.5rem"}}>
                        <Card.Text style={{padding: "0.7rem"}}>
                            <Select value={parHCV1Mode} onChange={onOperatingModeChange} options={operationModeOptions} />
                        </Card.Text>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{parHCV1ModeTime}</Card.Footer>
                </Card>
            </Col>
            <Col className="mb-2" xs={6} sm={4} md={4} lg={3} key={devicesParam.data[1].id}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[1].name}</b></Card.Header>
                    <Card.Body style={{padding: "0.62rem", fontSize: "2.0rem"}}>
                        <Card.Text style={{color: "bisque"}}>{HC1STS}</Card.Text>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{HC1STSTime}</Card.Footer>
                </Card>
            </Col>
            <Col className="mb-2" xs={12} sm={6} md={4} lg={3} key={devicesParam.data[2].id}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[2].name}</b></Card.Header>
                    <Card.Body style={{padding: "0", fontSize: "2.5rem"}}>
                        <Card.Text style={{color: "mediumaquamarine"}}>{HCV1WeatherCurve}<small>{devicesParam.data[2].unit}</small></Card.Text>
                        <Slider value={HCV1WeatherCurve} min={0} max={29} onChange={onHeatingSliderChange}/>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{HCV1WeatherCurveTime}</Card.Footer>
                </Card>
            </Col>
            <Col className="mb-2" xs={12} sm={6} md={4} lg={3} key={devicesParam.data[3].id}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[3].name}</b></Card.Header>
                    <Card.Body style={{padding: "0", fontSize: "2.5rem"}}>
                        <Card.Text style={{color: "mediumaquamarine"}}>{HC1DT}<small>{devicesParam.data[3].unit}</small></Card.Text>
                        <Slider value={HC1DT} min={0} max={100} onChange={onTemperatureSliderChange}/>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{HC1DTTime}</Card.Footer>
                </Card>
            </Col>
            <Col className="mb-2" xs={6} sm={4} md={4} lg={3} key={devicesParam.data[4].id}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[4].name}</b></Card.Header>
                    <Card.Body style={{padding: "0.25rem", fontSize: "2.5rem"}}>
                        <Card.Text style={{color: HC1T > 50 ? "firebrick" : "cornflowerblue"}}>{HC1T}<small>{devicesParam.data[4].unit}</small></Card.Text>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{HC1TTime}</Card.Footer>
                </Card>
            </Col>
            <Col className="mb-2" xs={6} sm={4} md={4} lg={3} key={devicesParam.data[5].id}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[5].name}</b></Card.Header>
                    <Card.Body style={{padding: "1.125rem", fontSize: "2.5rem"}}>
                        <Led value={HC1R}/>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{HC1RTime}</Card.Footer>
                </Card>
            </Col>
            <Col className="mb-2" xs={6} sm={4} md={4} lg={3} key={devicesParam.data[6].id}>
                <Card bg="dark" text="white" style={{fontSize: "0.75rem", textAlign: "center"}}>
                    <Card.Header style={{padding: "0.25rem"}}><b>{devicesParam.data[6].name}</b></Card.Header>
                    <Card.Body style={{padding: "1.125rem", fontSize: "2.5rem"}}>
                        <Led value={HC1P}/>
                    </Card.Body>
                    <Card.Footer style={{padding: "0.25rem"}}>{HC1PTime}</Card.Footer>
                </Card>
            </Col>
        </Row>
        </>
    ) : (
        <Row>
            <Col className="mb-2" xs={12} sm={12} md={12} lg={12}>
                <Card body bg="secondary" className="text-center">{NO_DEVICE_PARAMETERS}</Card>
            </Col>
        </Row>
    )
}

export default HeatingCircleOne