import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Form, Button } from "react-bootstrap";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useAuth } from "../../services/authService";
import { alertService } from "../../services/alertService";

const Login = (props) => {
    const history = useHistory();
    const location = useLocation();
    const auth = useAuth();

    const [submitting, setSubmitting] = useState(false);

    const initialValues = {
        email: '',
        password: ''
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('Email is required')
            .email('Invalid Email address'),
        password: Yup.string()
            .required('Password is required')
            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/, 'Must contain one uppercase, one lowercase and one number.')
            .min(8, 'Password should have at least 8 chars')
    });

    useEffect(() => {
        props.componentTitle("Login");
    }, [props]);

    function onSubmit(values) {
        alertService.clear();
        setSubmitting(true);
        auth.login(values)
            .then(() => {
                const { from } = location.state || { from: { pathname: "/" } };
                history.push(from)
            })
            .catch(error => {
                setSubmitting(false);
                alertService.error(error);
            });
    }

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors
            }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group size="lg" controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            name="email"
                            value={values.email}
                            // placeholder="john.doe@domain.com"
                            onChange={handleChange}
                            // isValid={touched.email && !errors.email}
                            isInvalid={touched.email && errors.email}
                        />
                        <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group size="lg" controlId="password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            name="password"
                            value={values.password}
                            // placeholder="***************"
                            onChange={handleChange}
                            // isValid={touched.password && !errors.password}
                            isInvalid={touched.password && errors.password}
                        />
                        <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                    </Form.Group>

                    <Button block size="lg" type="submit" disabled={submitting}>
                        {submitting ? "Submitting..." : "Login"}
                    </Button>

                    <div className="float-right">
                        <Link to="forgot-password" className="text-decoration-none text-secondary"><small>Forgot?</small></Link>
                    </div>

                    <div className="text-center" style={{paddingTop: "2rem"}}>
                        <Link to="register"className="text-decoration-none text-secondary">Register</Link>
                    </div>
                </Form>
            )}
        </Formik>
    );
}

export default Login