import React, { useState } from 'react'
import { Form, Button, Modal } from 'react-bootstrap'
import { BsPencil } from 'react-icons/bs';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { useAuth } from "../../../services/authService";
import { uniqueKeyService } from "../../../services/uniqueKeyService";
import { alertService } from "../../../services/alertService";
import { Crud } from "../../../helpers/crudAction";

const Edit = (props) => {
    const TITLE = "Edit";
    const SUBMIT = "Submit";
    const SUBMITTING = "Submitting";

    const auth = useAuth();

    const [show, setShow] = useState(false)
    const [submitting, setSubmitting] = useState(false);
    const [entity, setEntity] = useState(props.item);
    const handleShow = () => { setShow(true); }
    const handleClose = () => { setShow(false); }

    const validationSchema = Yup.object().shape({
        uuid: Yup.string()
            .required('Required field')
            .min(36, 'Should have at least 36 chars'),
        serials: Yup.array().of(Yup.number()),
        note: Yup.string(),
        activated: Yup.bool()
    });

    function onSubmit(values) {
        setSubmitting(true);
        values.serials = values.serials.map(serial => parseInt(serial));
        values.updatedBy = auth.user.username;
        uniqueKeyService.update(entity.id, values)
            .then((response) => {
                setEntity(response);
                props.refreshList(response, Crud.EDIT);
            })
            .catch(error => {
                alertService.error(error);
            })
            .finally(() => {
                setSubmitting(false);
                handleClose();
            });
    }

    return (
        <>
        <Button variant="warning float-right" size="sm" onClick={handleShow}><BsPencil/></Button>

        <Modal show={show} onHide={handleClose} animation={false} backdrop="static" keyboard={false} id="edit-modal">
            <Modal.Header closeButton id="edit-modal-header">
                <Modal.Title>{TITLE}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik enableReinitialize={true} initialValues={entity} validationSchema={validationSchema} onSubmit={onSubmit}>
                    {({ handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        errors,
                    }) => (
                        <Form id="edit-form" noValidate onSubmit={handleSubmit}>
                            <Form.Group size="lg" controlId="serialNumber">
                                <Form.Label>UUID</Form.Label>
                                <Form.Control
                                    disabled
                                    type="text"
                                    name="uuid"
                                    value={values.uuid ?? ''}
                                    onChange={handleChange}
                                    isInvalid={touched.uuid && errors.uuid}
                                />
                                <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{errors.uuid}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group size="lg" controlId="serial">
                                <Form.Label>Serials</Form.Label>
                                <Form.Control
                                    as="select"
                                    multiple
                                    name="serials"
                                    value={values.serials}
                                    onChange={handleChange}
                                    isInvalid={touched.serials && errors.serials}
                                >
                                    {props.serials.map((serial, i) => (
                                        <option key={i} value={serial.id} label={serial.number} />
                                    ))}
                                </Form.Control>
                                <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{errors.serials}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group size="lg" controlId="note">
                                <Form.Label>Note</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name="note"
                                    value={values.note ?? ''}
                                    onChange={handleChange}
                                    isInvalid={touched.note && errors.note}
                                />
                                <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{errors.note}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group size="lg" controlId="activated">
                                <Form.Check
                                    name="activated"
                                    label="Activated"
                                    onChange={handleChange}
                                    isInvalid={errors.activated}
                                    feedback={errors.activated}
                                    checked={values.activated}
                                />
                            </Form.Group>
                        </Form>
                    )}
                </Formik>
                <hr/>
                <small className="text-muted">Last updated: {new Date(entity.updatedAt).toUTCString()}, by {entity.updatedBy}</small>
            </Modal.Body>
            <Modal.Footer>
                <Button form="edit-form" type="submit" disabled={submitting}>
                    {submitting ? SUBMITTING+"..." : SUBMIT}
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    );
}

export default Edit